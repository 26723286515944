import '../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import * as FaIcons from "react-icons/fa";
import { Tooltip as ReactTooltip } from 'react-tooltip';

const url = process.env.REACT_APP_API_URL;

class DefaultOfficeCustomerInfo extends React.Component {
    constructor(props){

        super(props);
        this.state = {
            officeCustomerMap: props.officeCustomerMap,
            principalInfo:"",
            secondaryInfo:""
        }
        let customerOfficeToShow;
        if(this.state.officeCustomerMap.customerName){
            customerOfficeToShow = this.state.officeCustomerMap.customerName;
        } 
        if(this.state.officeCustomerMap.customerAlias){
            customerOfficeToShow += " - "+this.state.officeCustomerMap.customerAlias;
        } 
        if(this.state.officeCustomerMap.officeType != "" || this.state.officeCustomerMap.officeType != null
         || this.state.officeCustomerMap.officeType != undefined){
            customerOfficeToShow += " - "+i18n.translate("office_type_"+this.state.officeCustomerMap.officeType);
        } 
        if(this.state.officeCustomerMap.officeName){
            customerOfficeToShow += " - "+this.state.officeCustomerMap.officeName;
        }
        if(this.state.officeCustomerMap.documentType){
            customerOfficeToShow += " - "+i18n.translate("document_type_"+this.state.officeCustomerMap.documentType)+": "+this.state.officeCustomerMap.documentNumber;
        } 
        if(this.state.officeCustomerMap.officeDescription){
            customerOfficeToShow += " - "+this.state.officeCustomerMap.officeDescription;
        }
        this.state.principalInfo = customerOfficeToShow;
        customerOfficeToShow = "";
        if(this.state.officeCustomerMap.phone){
            customerOfficeToShow += this.state.officeCustomerMap.phone
        } 
        if(this.state.officeCustomerMap.cellPhone){
            customerOfficeToShow += " - "+this.state.officeCustomerMap.phone
        } 
        if(this.state.officeCustomerMap.email){
            customerOfficeToShow += " - "+this.state.officeCustomerMap.email
        } 
        if(this.state.officeCustomerMap.officeAddress){
            customerOfficeToShow += " - "+this.state.officeCustomerMap.officeAddress
        }
        this.state.secondaryInfo = customerOfficeToShow;
    }

    showSecondaryIfo = (action) => {
        if(action == 'show'){
            document.querySelector("#iconShow").style.display = 'none';
            document.querySelector("#iconHide").style.display = '';
            document.querySelector("#officeCustomerSecondaryInfo").style.display = '';
        }else{
            document.querySelector("#iconShow").style.display = '';
            document.querySelector("#iconHide").style.display = 'none';
            document.querySelector("#officeCustomerSecondaryInfo").style.display = 'none';
        } 
    } 

    render(){   
        return(
            <>
                <div id='officeCustomerPrincipalInfo'>
                    {this.state.principalInfo} 
                    <ReactTooltip id={"showSecondaryOfficeCustomerInfo"} place="top" effect="solid"/>
                    {this.state.secondaryInfo != '' && <>
                        <FaIcons.FaPlus id="iconShow" style={{cursor:'pointer',marginLeft:'5px',color:'#018'}} onClick={() => this.showSecondaryIfo('show')}
                        data-tooltip-id={"showSecondaryOfficeCustomerInfo"} data-tooltip-content={i18n.translate("default_show")}/>
                    </>} 

                    <FaIcons.FaMinus id="iconHide" style={{cursor:'pointer',marginLeft:'5px',color:'#018',display:'none'}} onClick={() => this.showSecondaryIfo('hide')}/>
                </div>
                <div id='officeCustomerSecondaryInfo' style={{display:'none'}}>
                    {this.state.secondaryInfo}
                </div>
            </>
        )
    }   
}

export default DefaultOfficeCustomerInfo;