import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import RemoveContent from "../../img/remove_content.png";
import AddContent from "../../img/add_content.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import { getArrayValues, setArrayValues } from './getArrayValues';
import { DescriptionConfig, DescriptionGIE, NewGIE } from './DescriptionConfig';

const url = process.env.REACT_APP_API_URL;

class DynamicMapTemplate extends React.Component {
    constructor(props){
        var count = 1;
        var values = [];
        var valuesToUpdate = [];
        var valuesToShow = [];
        var valuesToUpdateNumeric = [];
        var valuesToShowNumeric = [];
        var valuesToShowBoolean = [];
        var valuesToUpdateBoolean = [];
        super(props);
        this.state = {
            values: [],
            valuesToUpdate: [],
            valuesToShow: [], 
            valuesToUpdateBoolean: [],
            valuesToShowBoolean: [],
            valuesToUpdateNumeric: [],
            valuesToShowNumeric: [],
            classOptionDays:"",
            title:"",
            numbers : [
                { value: '0'},{ value: '10'},{ value: '20'},{ value: '30'},{ value: '40'},{ value: '50'},{ value: '60'},
                { value: '70'},{ value: '80'},{ value: '90'},{ value: '100'},{ value: '110'},{ value: '120'}
            ],
            form:props.array,
            detail: props.det
        } 
        if(this.state.detail){
            values = this.state.detail.value;
            values = JSON.parse(values);
            
        }else{
            {this.state.form.detail.map((elem) =>{
                if(elem.type == 0){
                    values = elem.value;
                    values = JSON.parse(values);
                    
                }
            })} 
        }
        Object.keys(values).forEach((e) => {
            if(values[e].type == "boolean" || values[e].type == 'BOOLEAN'){
                if(e == "allowsRescheduling" && values[e].value == "false"){
                    this.state.classOptionDays = "displayNone";
                } 
                valuesToShowBoolean.push({"assigned":e,"val":values[e].value});
                valuesToUpdateBoolean.push({"assigned":e,"val":values[e].value});
            }else if(values[e].type == "numeric" || values[e].type == 'int'){
                valuesToShowNumeric.push({"assigned":e,"val":values[e].value});
                valuesToUpdateNumeric.push({"assigned":e,"val":values[e].value});
            }else if(values[e].type == 'ARRAY_STRING'){
                this.state.title = e
                values = values[e].value;
                values.map((e) => {
                    valuesToShow.push(count+ " - "+e)
                    valuesToUpdate.push(e);
                    count++; 
                });
            }
        })
        this.state.valuesToShowBoolean = valuesToShowBoolean;
        this.state.valuesToUpdateBoolean = valuesToUpdateBoolean;
        this.state.valuesToShowNumeric = valuesToShowNumeric;
        this.state.valuesToUpdateNumeric = valuesToUpdateNumeric;
        this.state.valuesToShow =  valuesToShow;
        this.state.valuesToUpdate =  valuesToUpdate;
    }


    handleChange=e=>{
        let valuesToUpdateNumeric = [];
        if(e.target.id == "standard_duration" || e.target.id == "daysToAddWhenRescheduling"){
            valuesToUpdateNumeric.push({"assigned":e.target.id,"val":e.target.value})
            this.setState({valuesToUpdateNumeric:valuesToUpdateNumeric})
        }else if(e.target.id == "allowsRescheduling"){
            if(e.target.value == "true"){
                document.querySelector("#daysToAddWhenRescheduling").parentNode.style.display = "";
                this.setState({classOptionDays:""});
                valuesToUpdateNumeric.push({"assigned":"daysToAddWhenRescheduling","val":"10"})
                this.setState({valuesToUpdateNumeric:valuesToUpdateNumeric})
            }else{
                document.querySelector("#daysToAddWhenRescheduling").parentNode.style.display = "none";
                valuesToUpdateNumeric.push({"assigned":"daysToAddWhenRescheduling","val":"0"})
                this.setState({valuesToUpdateNumeric:valuesToUpdateNumeric})
            } 
        }else{
            var newValues = []; 
            let inputs = document.querySelectorAll(".arrayStringValue input");
            let arrayInput = Array.from(inputs);
            arrayInput.map((s) => newValues.push(s.value));
            this.setState({valuesToUpdate:newValues});
        }
    }

    removeOption=e=>{
        this.setState({valuesToUpdate:getArrayValues(e)}); 
    }
    
    addOption =e=>{
        this.setState({valuesToUpdate:setArrayValues(e)});
    }

    render(){
        return(
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <DescriptionConfig keyValue={this.state.form.key}/>
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("current_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        {this.state.valuesToShowBoolean.map((det) =>{
                           return(
                            <div><b>{i18n.translate(det.assigned)}: </b><label>{i18n.translate(det.val)}</label></div>
                            )
                        })}
                        {this.state.valuesToShowNumeric.map((det) =>{
                           return(
                            <div><b>{i18n.translate(det.assigned)}: </b><label>{det.val}</label></div>
                            )
                        })}
                        {this.state.title != "" && <b>{i18n.translate(this.state.title)}:</b>}
                        <br/>
                        {this.state.valuesToShow.map((det) =>{
                           return(
                            <label style={{display:'block'}}>{det}</label>
                            )
                        })}
                    </div>
                </div>
                {this.state.form.update != false &&
                    <DescriptionGIE type={this.state.detail.type}/>
                }
                {this.state.form.update == false && 
                    <NewGIE/>
                }
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("new_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9 dynamicMapValue'>
                        {this.state.valuesToUpdateBoolean.map((det) =>{
                           return(
                            <div>
                               <b>{i18n.translate(det.assigned)}:</b>
                               <select class='form-select selectStandard newValue' onChange={this.handleChange} id={det.assigned}>
                                {det.val == "true" ?  <option value="true" selected>{i18n.translate("true")}</option> : <option value="true">{i18n.translate("true")}</option>}
                                {det.val == "false" ?  <option value="false" selected>{i18n.translate("false")}</option> : <option value="false">{i18n.translate("false")}</option>}
                               </select>
                            </div>
                            )
                        })}
                        {this.state.valuesToUpdateNumeric.map((det) =>{
                           return(
                            <div className={this.state.classOptionDays}>
                               <b>{i18n.translate(det.assigned)}:</b>
                               <select className={'form-select selectStandard newValue'} id={det.assigned} value={det.val} onChange={this.handleChange}>
                               {this.state.numbers.map((n)=>{
                                return (
                                    <option value={n.value}>{n.value}</option>
                                )
                               })}
                               </select>
                            </div>
                            )
                        })}
                        {this.state.title != "" && <b>{i18n.translate(this.state.title)}:</b>}
                        <br/>
                        {this.state.valuesToUpdate.map((det) =>{
                           return(
                            <div class='arrayStringValue'>
                                <img onClick={this.removeOption} className='removeContent' src={RemoveContent} width="20px" height="20px"/>
                                <input onChange={this.handleChange} name={det} class='form-control inputStandard' type='text' value={det}/>
                            </div>
                            )
                        })}
                        {this.state.form.key != "PROFILES_THAT_CAN_CREATE_TASKS" ? <div>
                            <img onClick={this.addOption} className='addContent' src={AddContent} width="20px" height="20px"/>
                        </div> : ''}
                        
                    </div>
                </div>
            </div>
            
        )
    } 
    
} 

export default DynamicMapTemplate;