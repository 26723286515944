import React from "react";
import logoMzate from '../img/independent/LogoMovilizateBlanco.png';
import logoCollapseMzate from '../img/independent/arrows.png';
import logoCast from '../img/cast_mzate/LogoMovilizateBlanco.png';
import logoCollapseCast from '../img/cast_mzate/arrows.png';
import faviconCast from '../img/cast_mzate/favicon.ico'
import favicon from '../img/independent/favicon.ico'
import { useEffect, useState } from "react";
import axios from 'axios';
const url = process.env.REACT_APP_API_URL;

const LogoContainer = () => {
    const [instanceType, setInstanceType] = useState(null);
    const [instanceTypeDescription, setInstanceTypeDescription] = useState(null);
    const [independent, setIndependent] = useState(null);
    const [logo, setLogo] = useState("");
    const [logoCollapse, setLogoCollapse] = useState("");
    useEffect(() => {
        axios.get(url+"globalConfig.json").then(response => {
            setInstanceType(response.data.instanceType);
            setInstanceTypeDescription(response.data.instanceTypeDescription);
            setIndependent(response.data.independent);
            if(response.data.instanceType === response.data.independent){
                setLogo(logoMzate);
                setLogoCollapse(logoCollapseMzate);
            }else if(response.data.instanceType === response.data.castMzate){
                setLogo(logoCast);
                setLogoCollapse(logoCollapseCast);
            } 
        })
    });
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    if(instanceType != independent){
        link.href = faviconCast
    }else{
        link.href = favicon
    }
    document.title = instanceTypeDescription;
    return (
        <div className="logoContainer">
            <a className="nav-item nav-link" id="nav-movilizate-tab" role="tab" aria-controls="nav-movilizate">
                <img alt="Movilizate" className="mzateLogo" src={logo} width="96%"></img>
                <img alt="Movilizate" className="mzateCollapseLogo displayNone" src={logoCollapse} width="82.5%"></img>
            </a>
        </div>
    );
};
    
export default LogoContainer;