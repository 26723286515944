import React from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";

export const SidebarDataMzateAdministration = [
    {
        title: "videos_title",
        path: "/AdministrationMzate/videos",
        icon: <FaIcons.FaVideo />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
        title: "audit_title",
        path: "/AdministrationMzate/audit",
        icon: <FaIcons.FaEye />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
        title: "storages_ranges_title",
        path: "/AdministrationMzate/storage",
        icon: <FaIcons.FaDatabase />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
        title: "settings_title",
        path: "/AdministrationMzate/configurations",
        icon: <FaIcons.FaCog />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
        title: "mobile_installation_audit",
        path: "/AdministrationMzate/appInstallAudit",
        icon: <FaIcons.FaCog />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
        title: "mzate_platform",
        path: "",
        icon: <FaIcons.FaCogs />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        id: 'mzatePlatform'
    
    }
]