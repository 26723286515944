import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarTasks from "../components/SidebarTasks";
import Config from "./tasksContent/Config";
import { useLocation } from 'react-router-dom';
import TaskIndex from "./tasksContent/TaskIndex";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions";

const Tasks = ({language}) =>{
    var content = "";
    
    var url = useLocation().pathname;
    if(url === "/" || url === "/Tareas/tareas" || url === "/Tareas"){
        content = <TaskIndex language={language}/>;
    }else if(url === "/Tareas/configuraciones"){
        content = <Config/>;
    }
    if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
        window.history.replaceState(null,document.title,window.location.origin + "/" + '?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
    } 

    var flag = 0;
	const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }
 
    return (
        <div className="tab-pane fade" id="nav-controlTasks" role="tabpanel" aria-labelledby="nav-controlTasks-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarTasks/>
            </div>
            <div className="content" id="contentAdministration">
                {content}
            </div>
        </div>
    );
    
};
export default Tasks;
