export const getDynamicMapValues = (valor) => {
    let selects = document.querySelectorAll(".dynamicMapValue select");
    for(var i = 0; i < selects.length; i++){
        Object.keys(valor).forEach((e) => {
            if(e == selects[i].id){
                valor[e].value = selects[i].value;
            }
        });
    }
    var tasksToDo = [];
    let inputs = document.querySelectorAll(".arrayStringValue input");
    for(var i = 0; i < inputs.length; i++){
        tasksToDo.push(inputs[i].value);
    }
    Object.keys(valor).forEach((e) => {
        if(e == "tasks_to_do"){
            valor[e].value = tasksToDo;
        }
    });
    return(valor)
}