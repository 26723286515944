import '../../App.css';
import React from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import i18n from "i18n-react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DefaultModal from '../../components/DefaultModal';

const url = process.env.REACT_APP_API_URL;

class AppInstallAudit extends React.Component {
    constructor(props){
        super(props);
        this.state={
            spinner:"",
            modalContent:"",
            listAudit: [],
            listSearchAudit: [], 
            startDate: moment().add(-12, 'hour'),
            endDate: moment().add(12, 'hour'), 
            dateRange:[],
        } 
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment().add(-12, 'hour'))));
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment().add(12, 'hour'))));
    } 

    componentDidMount(){
        this.getListApp();
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbarT").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 50;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
    }

    dateRangeForSearh = (date) => {
        let startDate = new Date(date);
        let m = startDate.getMonth() + 1;
        let mes = (m < 10) ? '0' + m : m;
        let day = startDate.getDate();
        let dayForShow = (day < 10) ? '0' + day : day;
        startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
        return startDate;
    }

    filterAppList = e =>{
        let filterSearch = e.target.value.toLowerCase();

        let searchRes = this.state.listAudit.filter((e) => {
            let username = e.username.toLowerCase();
            let serviceName = e.serviceName.toString();
            let fullname = e.fullname.toString();
            return username.indexOf(filterSearch) !== -1 || serviceName.indexOf(filterSearch) !== -1 || fullname.indexOf(filterSearch) !== -1
        })
        if(e.target.value === ''){
            searchRes = this.state.listSearchAudit;
        }
        this.setState({listAudit:searchRes});
    } 

    getListApp = (showAll) =>{
        
        let startDate = this.state.dateRange[0];
        let endDate = this.state.dateRange[1];
        if(showAll){
            startDate = "";
            endDate = "";
        } 

        DefaultAjax('').get("/installAppAudit?startDate="+startDate+"&endDate="+endDate)
        .then((res) =>{
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    listAudit:result,
                    listSearchAudit:result
                })
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    showAppDetail = (app) => {
        this.setState({modalContent:""});
        setTimeout(()=> {
            let modalContent = this.getDetailContent(app);
            let buttons = [{text: i18n.translate("default_close"), function: ""}]  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("default_details")}
            content={modalContent} buttons={buttons}/>})
        }, 10);
    }
    
    getDetailContent = (app) => {
        return(
            <>
                {app.detail.map((detail) => {
                    return(
                        <>
                            <Row style={{paddingTop:'10px'}}>
                                <Col style={{fontWeight:'bolder'}}>
                                    {i18n.translate("event_date")} 
                                </Col>
                                <Col>
                                    {detail.eventDate} 
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{fontWeight:'bolder'}}>
                                    {i18n.translate("event_type")} 
                                </Col>
                                <Col>
                                    {i18n.translate(detail.eventType)} 
                                </Col>
                            </Row>
                            <Row style={{paddingBottom:'10px',borderBottom:'1px solid gray'}}>
                                <Col style={{fontWeight:'bolder'}}>
                                    {i18n.translate("show_notification")} 
                                </Col>
                                <Col>
                                    {detail.showNotification} 
                                </Col>
                            </Row> 
                        </>
                    )
                })}
            </>
        )
    } 

    handleCallback = (start, end, label) => {
        this.setState({dateRange:""})
        let dateRange = []; 
        dateRange.push(this.dateRangeForSearh(start._d));
        dateRange.push(this.dateRangeForSearh(end._d));
        this.setState({
            dateRange:dateRange,
            startDate: start._d,
            endDate:end._d
        });
    }

    render(){
        return (
            <>
                {this.state.modalContent} 
                <br/>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover></ToastContainer>
                <nav className='navbarT'>
                    <Row>
                        <Col sm={12} md={3} lg={3} xl={3}>
                            <label>{i18n.translate("default_search")}</label>
                            <input className="form-control inputStandard" type='text' onChange={this.filterAppList}/>
                        </Col>
                        <Col sm={12} md={3} lg={3} xl={3} style={{paddingTop:'5px'}}>
                            <label>{i18n.translate("installation_date")}</label>
                            <DateRangePicker
                                initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate }}
                                onCallback={this.handleCallback}
                            >
                                <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard" />
                            </DateRangePicker>
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} style={{paddingTop:'15px'}}>
                            <button style={{float: 'right'}} className='buttonMzateSecondary' onClick={()=>this.getListApp(true)}>{i18n.translate("default_show_all")}</button>
                            {''}
                            <button style={{float: 'right',marginRight: '10px'}} className='buttonMzateSecondary' onClick={()=>this.getListApp()}>{i18n.translate("default_search")}</button>
                        </Col>
                    </Row>
                    <br/><br/>
                </nav>
                <div className='bodyContent' id='bodyContent'>
                    {this.state.spinner}
                    <Table>
                        <thead className="table-dark" style={{position:'sticky',top:'0'}}>
                            <tr>
                                <th>{i18n.translate("default_service")}</th>
                                <th>{i18n.translate("default_user")}</th>
                                <th>{i18n.translate("device_id")}</th>
                                <th>{i18n.translate("app_version")}</th>
                                <th>{i18n.translate("installation_date")}</th>
                                <th>{i18n.translate("configuration_date")}</th>
                                <th>{i18n.translate("firebase_message_token")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.listAudit.map((list) => {
                                return (
                                    <tr key={list.id} style={{cursor:'pointer'}} onClick={()=>this.showAppDetail(list)}>
                                        <td>
                                            {list.tenantId} - {list.serviceName} 
                                        </td>
                                        <td>
                                            {list.fullname} - {list.username} 
                                        </td>
                                        <td>
                                            {list.deviceId} 
                                        </td>
                                        <td>
                                            {list.appVersion} 
                                        </td>
                                        <td>
                                            {list.dateInstalled} 
                                        </td>
                                        <td>
                                            {list.configurationDate} 
                                        </td>
                                        <td>
                                            {list.firebaseMessageToken} 
                                        </td>
                                    </tr>
                                )
                            })} 
                        </tbody>
                    </Table>
                </div>
            </>
        )
    } 
} 

export default AppInstallAudit;