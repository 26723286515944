import React from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import informacion from "../img/iconos-roll-over/informacion.png";
import facturacion from "../img/iconos-roll-over/facturacion.png";

export const SidebarDataMyAccount = [
    {
        title: "default_information",
        path: "/MyAccount/",
        icon: <img src={informacion} width="32px"/>,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        id: 'myAccountInformation'
    },
    {
        title: "default_billing",
        path: "/MyAccount/",
        icon: <img src={facturacion} width="32px"/>,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        id: 'myAccountBilling'
    },
    {
        title: "storages_ranges_title",
        path: "/MyAccount/storage",
        icon: <FaIcons.FaDatabase />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    }
]