import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import i18n from "i18n-react";
import NavDropdown from 'react-bootstrap/NavDropdown';

const SidebarLink = styled(Link)`
display: flex;
color: #e1e9fc;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 60px;
text-decoration: none;
font-size: 18px;

&:hover {
	background: #252831;
	border-left: 4px solid green;
	cursor: pointer;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
`;

const DropdownLink = styled(Link)`
background: #252831;
height: 60px;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
color: #f5f5f5;
font-size: 18px;

&:hover {
	background: green;
	cursor: pointer;
}
`;

const Menu = ({ item,index,platformUrl,pathRoot, token}) => {
const [subnav, setSubnav] = useState(false);

const showSubnav = () => setSubnav(!subnav);

const handleClick = event => {
	if(event.target.id == "tutorialVideos"){
		if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
			window.history.replaceState(null,document.title,window.location.origin + "/" + '?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
		}
	} 
	let token = document.querySelector("#token").value;
	if(event.target.id === "controlPanelPlatform"){
		let html = "dashboardMain.html";
		if(document.querySelector("#currentModule").value === 'administrationMzate'){
			html = "dashboard.html"
		}
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/"+html+"?lang=es?module=controlPanelFromAppWeb";
	}else if(event.target.id === "visitsPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=visitsFromAppWeb";
	}else if(event.target.id === "myAccountPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=myAccountFromAppWeb";
	}else if(event.target.id === "opportunitiesPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=opportunitiesFromAppWeb";
	}else if(event.target.id === "ordersPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=ordersFromAppWeb";
	}else if(event.target.id === "professionalVisitsPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=professionalVisitsFromAppWeb";
	}else if(event.target.id === "professionalManagementPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=professionalManagementFromAppWeb";
	}else if(event.target.id === "geofencesPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=geofencesFromAppWeb";
	}else if(event.target.id === "routesPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=routesFromAppWeb";
	}else if(event.target.id === "administrationPlatform" && document.querySelector("#currentModule").value != 'administrationMzate'){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=administrationFromAppWeb";
	}else if(event.target.id === "supportPlatform"){
		window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboard.html?lang=es?module=supportFromAppWeb";
	} 

	let li = document.querySelectorAll(".fixed-top a");
	let arrayLi = Array.from(li);
	arrayLi.map((li) =>{
		if(li.classList.contains("active")){
			li.classList.remove("active");
		} 
	})
	event.currentTarget.classList.add('active');

};
return (
	<>
		<SidebarLink to={item.path} onClick={handleClick} id={item.id} className={item.className}>
			<div id={item.id}>
			<SidebarLabel id={item.id}>{i18n.translate(item.title)}</SidebarLabel>
			</div>
			<div>
			{item.subNav && subnav
				? item.iconOpened
				: item.subNav
				? item.iconClosed
				: null}
			</div>
		</SidebarLink>
	</>
);
};

export default Menu;
