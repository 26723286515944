import React from "react";
import logoMzate from '../../img/independent/LogoMovilizateBlanco.png';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import DefaultModal from '../../components/DefaultModal';
import DefaultSpinner from "../../components/DefaultSpinner";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import {  Row, Col, Table } from 'reactstrap';
import SelectGIE from '../../components/SelectGIE';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CreditNoteIndex extends React.Component {
    constructor(props){
        super(props);
        this.state={
            arrayCreditNote: [],
            modalContent:"",
            modalContent2:"",
            instanceType:"",
            instanceTypeDescription:"",
            independent:"",
            castMzate:"",
            spinner:"",
            startDate: moment().add(-12, 'hour'),
            endDate: moment().add(12, 'hour'),
            selectOrgType:"", 
            dateRange: [],
            currentCreditNoteShow: "",
            billing: ""
        } 
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment().add(-12, 'hour'))));
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment().add(12, 'hour'))));
    } 

    componentDidMount() {
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-2 col-lg-2 col-xl-2 selectGIE"} classSelectType={"col-sm-12 col-md-2 col-lg-2 col-xl-2"}/>
        });
        this.getAllCreditNote(false);
    } 

    getAllCreditNote = (isSearch) => {
        let orgNodeId,type = "";

        if(isSearch){
            if(document.querySelector(".contentInput .selectType").value == "1"){
                orgNodeId = document.querySelector(".contentInput #profileSelected").value
            }else if(document.querySelector(".contentInput .selectType").value == "2"){
                orgNodeId = document.querySelector(".contentInput #groupSelected").value
            }else{
                orgNodeId = document.querySelector(".contentInput #userSelected").value;
            }
            type = document.querySelector(".contentInput .selectType").value;
        }  
        
        // let arrayCreditNote = [
        //     {
        //         id:1,
        //         dateIssue:"2024-25-02",
        //         customerName: "customer1",
        //         customerFiscalNumber: "55555",
        //         responsibleUser: "responsible1",
        //         exemptTotal: "12",
        //         netTotal: "11",
        //         vat05Total: "5",
        //         vat10Total: "10",
        //         creditNoteNumber: "0-11111",
        //         invoiceNumber: "0-11111",
        //         expirationDate: "2024-05-02",
        //         invoiceFiscalStampNumber: "00001",
        //         invoiceSaleCondition: "Contado",
        //         startOfValidity: "2024-02-02",
        //         endOfValidity: "2024-04-02",
        //         currency: "Guaraní",
        //         reasonForReturn: "Devolución",
        //         detail: [
        //             {
        //                 code: "111",
        //                 description: "description1",
        //                 price: "1000",
        //                 amount:"2",
        //                 vat: "11",
        //                 vatDescription: "iva description",
        //                 vatTaxableProportion: "iva proportion"
        //             } 
        //         ] 
        //     },
        //     {
        //         id:2,
        //         dateIssue:"2024-25-02",
        //         customerName: "customer2",
        //         customerFiscalNumber: "66666",
        //         responsibleUser: "responsible2",
        //         exemptTotal: "13",
        //         netTotal: "12",
        //         vat05Total: "6",
        //         vat10Total: "11",
        //         creditNoteNumber: "0-22222",
        //         invoiceNumber: "0-22222",
        //         expirationDate: "2024-05-02",
        //         invoiceFiscalStampNumber: "00002",
        //         invoiceSaleCondition: "Contado",
        //         startOfValidity: "2024-02-02",
        //         endOfValidity: "2024-04-02",
        //         currency: "Guaraní",
        //         reasonForReturn: "Descuento",
        //         detail: [
        //             {
        //                 code: "222",
        //                 description: "description2",
        //                 price: "1000",
        //                 amount:"2",
        //                 vat: "11",
        //                 vatDescription: "iva description",
        //                 vatTaxableProportion: "iva proportion"
        //             } 
        //         ]
        //     },
        //     {
        //         id:3,
        //         dateIssue:"2024-25-02",
        //         customerName: "customer3",
        //         customerFiscalNumber: "77777",
        //         responsibleUser: "responsible3",
        //         exemptTotal: "14",
        //         netTotal: "13",
        //         vat05Total: "7",
        //         vat10Total: "12",
        //         creditNoteNumber: "0-33333",
        //         invoiceNumber: "0-33333",
        //         expirationDate: "2024-05-02",
        //         invoiceFiscalStampNumber: "00003",
        //         invoiceSaleCondition: "Crédito",
        //         startOfValidity: "2024-02-02",
        //         endOfValidity: "2024-04-02",
        //         currency: "Guaraní",
        //         reasonForReturn: "Descuento",
        //         detail: [
        //             {
        //                 code: "333",
        //                 description: "description3",
        //                 price: "1000",
        //                 amount:"2",
        //                 vat: "11",
        //                 vatDescription: "iva description",
        //                 vatTaxableProportion: "iva proportion"
        //             } 
        //         ]
        //     } 
        // ] 
        let dto = {
            startDate: this.state.dateRange[0],
            endDate: this.state.dateRange[1],
            orgNodeId:orgNodeId,
            type:type
        }
        DefaultAjax('').get("electronicBilling/electronicBilling?paramsJSON="+encodeURI(JSON.stringify(dto)))
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data)
                this.setState({
                    arrayCreditNote:result,
                    spinner:"",
                })
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    dateRangeForSearh = (date) => {
        let startDate = new Date(date);
        let m = startDate.getMonth() + 1;
        let mes = (m < 10) ? '0' + m : m;
        let day = startDate.getDate();
        let dayForShow = (day < 10) ? '0' + day : day;
        startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
        return startDate;
    }

    handleCallback = (start, end, label) => {
        this.setState({dateRange:""})
        let dateRange = []; 
        document.querySelector("#dateInInputStart").value = this.dateRangeForSearh(start._d);
        document.querySelector("#dateInInputEnd").value = this.dateRangeForSearh(end._d);
        dateRange.push(this.dateRangeForSearh(start._d));
        dateRange.push(this.dateRangeForSearh(end._d));
        this.setState({
            dateRange:dateRange,
            startDate: start._d,
            endDate:end._d
        });
    }

    showCreditNote = (creditNote) => {
        this.setState({currentCreditNoteShow:creditNote});
        this.setState({modalContent:""});
        let modalContent;
        modalContent = this.creditNoteContentShow(creditNote);
        setTimeout(()=> {
            let buttons = [{text: i18n.translate("download_pdf"), function: this.downloadCreditNotePdf},
            {text: i18n.translate("see_invoice"), function: this.showBilling},
            {text: i18n.translate("default_close"), function: ""}];  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("credit_note")}
            content={modalContent} buttons={buttons} size='lg'/>})
        }, 10);
    } 

    downloadCreditNotePdf = () => {
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });
        let docDefinition = {
            //Specifying page size
                // pageSize: {
                //     width: 226.8,
                //     height: 'auto',
                //     },
                    //Specifying content of our file
    
                // pageSize: 'A4',
                // pageMargins: [10, 50, 10, 40],
                content: [
                    {
                        width: '50%',
                        columns: [
                            {
                                image:'logoMzate',
                                width:120
                            },
                            [
                                {
                                    text: ' Actividades de Informática',
                                    alignment: 'center',
                                    fontSize: 10,
                                    width: 170,
                                },
                                {
                                    text: 'Consultores de Programas en Informática',
                                    alignment: 'center',
                                    fontSize: 10,
                                    width: 170,
                                },
                                {
                                    text: 'Suministros de Programas en Informáticos',
                                    alignment: 'center',
                                    fontSize: 10,
                                    width: 170,
                                },
                            ],
                            [
                                {
                                    text: 'Timbrado: 123456788',
                                    alignment: 'center',
                                },
                                {
                                    text: 'R.U.C:80071425-3',
                                    alignment: 'center',
                                },
                                {
                                    text: 'Inicio de Vigencia: 11/03/2020',
                                    alignment: 'center',
                                }
                            ],  
                        ]
                    },
                    {
                        columns: [
                            [
                                {
                                    text: 'Agustín Barrios Nº 1029 e/San Rafael y MelvinJones',
                                    alignment: 'center',
                                    fontSize: 9,
                                    // width: 170,
                                    bold: true
                                },
                                {
                                    text: 'Tel.: 021 238 1101',
                                    alignment: 'center',
                                    fontSize: 9,
                                    // width: 170,
                                    bold: true
                                },
                                {
                                    text: 'Asunción - Paraguay',
                                    alignment: 'center',
                                    fontSize: 9,
                                    // width: 170,
                                    bold: true
                                },
                            ],
                            [
                                {
                                    text: 'NOTA DE CRÉDITO ELECTRÓNICA: 001-002-000049',
                                    alignment: 'center',
                                    bold: true
                                },
                                {
                                    text: 'Nro. Factura: 001 - 001 - 0000029',
                                    alignment: 'center',
                                    bold: true
                                }
                            ],
                        ] 
                    },
                    '\n',
                    {
                        columns: [
                            {
                                text: [
                                        {text: 'Fecha de emisión: ', bold: true},
                                        {text:'2024-02-02',alignment: 'right',},
                                        '\n',
                                        {text: 'Nombre o Razón Social: ', bold: true},
                                        {text:'21312',alignment: 'right',},
                                        '\n',
                                        {text: 'R.U.C: ', bold: true},
                                        {text:'123',alignment: 'right',},
                                    ] 
                            },
                            {
                                text: [
                                           {text: 'Condicion de Venta: ', bold: true},
                                           {text:'Contado',alignment: 'left',},
                                           '\n',
                                           {text: 'Moneda: ', bold: true},
                                           {text:'Guaraní',alignment: 'left',},
                                       ]
                            }
                        ]
                    }, 
                    '\n',
                    {
                        // style: 'tableExample',
                        table: {
                          // headers are automatically repeated if the table spans over multiple pages
                          // you can declare how many rows should be treated as headers
                            widths: ['*', '*', '*', '*', '*'],
                            headerRows: 1,
                          body: [
                            [ 'Cantidad', 'Descripción', 'Precio Unit.', 'Exentas','10%' ],
                            [ '1', 'Visitas y localización', '667', '0','1' ],
                            [{colSpan: 3, text: 'Subtotales'},'','','0', '67'],
                            [{colSpan: 4, text: 'Total a pagar: SEISCIENTOS SESENTA Y SIETE GUARANIES'},'','','', '67'],
                            [{colSpan: 2, text: 'Liquidación del (10%) 61'},'',{colSpan: 3, text: 'Total Iva 61'},'', ''],
                          ]
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex === 0) ? '#CCCCCC' : null;
                            }
                        }
                    },
                    '\n',
                    {
                        columns: [
                            {
                                text: [
                                    {text: 'Código QR', bold: true},
                                ],
                            },
                            {
                                text: [
                                    {text: 'Consulte la validez de esta Nota de Crédito en el número de CDC impreso abajo en:', bold: true}, 
                                    '\n',
                                    {text: 'https://:ekuatia.set.gov.py/consultas/',
                                    link: 'https://:ekuatia.set.gov.py/consultas/'},
                                    '\n',
                                    '\n',
                                    {text: 'ESTE DOCUMENTO ES UNA PRESENTACIÓN GRÁFICA DE UN DOCUMENTO ELECTRÓNICA (XML)'},
                                    '\n',
                                    {text: 'Información de interés del facturador electrónico emisor.', bold: true},
                                    '\n',
                                    {text: 'Si su documento electrónico presenta algun error podrá solicitar la modificación dentro de las 72 horas siguientes de la emisión de este comprobante'},
                                ]
                            }  
                        ] 
                    } 
                ],
                images : {
                    logoMzate : window.location.origin +logoMzate,
                },
                //Specifying our imported font as default style
                defaultStyle: {
                    header: {
                        fontSize: 18,
                        bold: false
                    },
                    subheader: {
                        fontSize: 15,
                        bold: true
                    },
                    quote: {
                        italics: true
                    },
                    small: {
                        fontSize: 8
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };
            //Creating pdf with pdfmake and downloading it
            pdfMake.createPdf(docDefinition).download();
            this.setState({
                spinner:""
            });
    } 

    showBilling = () => {
        // let billing = {
        //     id:1,
        //     dateIssue:"2024-25-02",
        //     customerName: "customer1",
        //     customerFiscalNumber: "55555",
        //     responsibleUser: "responsible1",
        //     exemptTotal: "12",
        //     netTotal: "11",
        //     vat05Total: "5",
        //     vat10Total: "10",
        //     invoiceNumber: "0-11111",
        //     expirationDate: "2024-05-02",
        //     invoiceFiscalStampNumber: "00001",
        //     invoiceSaleCondition: "Contado",
        //     startOfValidity: "2024-02-02",
        //     endOfValidity: "2024-04-02",
        //     currency: "Guaraní",
        //     sifenState: "Pendiente",
        //     detail: [
        //         {
        //             code: "111",
        //             description: "description1",
        //             price: "1000",
        //             amount:"2",
        //             vat: "11",
        //             vatDescription: "iva description",
        //             vatTaxableProportion: "iva proportion"
        //         } 
        //     ] 
        // }

        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            modalContent2:""
        });

        DefaultAjax('').get("electronicBilling/electronicBilling?billingId="+this.state.currentCreditNoteShow.id)
        .then((res) => {
            if(res){
                this.setState({
                    spinner:""
                });
                let result = JSON.parse(res.data)
                let modalContent;
                modalContent = this.billingContentShow(result);
                setTimeout(()=> {
                    let buttons = [{text: i18n.translate("default_close"), function: ""}];  
                    this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("default_bill")}
                    content={modalContent} buttons={buttons} size='lg'/>})
                }, 10);
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    billingContentShow = (billing) => {
        return (
            <>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_number")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.invoiceNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("date_issue")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.dateIssue}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("expiration_date")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.expirationDate}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_fiscal_stamp_number")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.invoiceFiscalStampNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_customer_name")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.customerName}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_sale_condition")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.invoiceSaleCondition}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("start_of_validity")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.startOfValidity}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_customer_ruc")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.customerFiscalNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("default_currency")} 
                        </label>
                        <br></br>
                        <label>
                            {billing.currency}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("sifen_state")}:  
                        </label>
                        <label style={{marginLeft:'5px'}}>
                            {billing.sifenState}  
                        </label>
                    </Col>
                </Row>
                <Table className='table table-hover'>
                    <tbody>
                        <tr>
                            <th className='firstcell'>{i18n.translate("default_code")}</th>
                            <th className='cellgroup'>{i18n.translate("description")}</th>
                            <th className='cellgroup'>{i18n.translate("storage_price")}</th>
                            <th className='cellgroup'>{i18n.translate("default_amount")}</th>
                            <th className='cellgroup'>{i18n.translate("default_iva")}</th>
                            <th className='cellgroup'>{i18n.translate("vat_description")}</th>
                            <th className='cellgroup'>{i18n.translate("vat_taxable_proportion")}</th>
                        </tr>
                        {billing.detail.map((det) =>{
                            return(
                                <tr key={det.code}>
                                    <td>
                                        {det.code} 
                                    </td>
                                    <td>
                                        {det.description} 
                                    </td>
                                    <td>
                                        {det.price} 
                                    </td>
                                    <td>
                                        {det.amount} 
                                    </td>
                                    <td>
                                        {det.vat} 
                                    </td>
                                    <td>
                                        {det.vatDescription} 
                                    </td>
                                    <td>
                                        {det.vatTaxableProportion} 
                                    </td>
                                </tr>
                            )
                        })} 
                    </tbody>
                </Table>
            </>
        )
    } 

    creditNoteContentShow = (creditNote) => {
        return (
            <>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("credit_number")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.creditNoteNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("date_issue")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.dateIssue}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("expiration_date")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.expirationDate}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_fiscal_stamp_number")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.invoiceFiscalStampNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_customer_name")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.customerName}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_sale_condition")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.invoiceSaleCondition}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'center'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("start_of_validity")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.startOfValidity}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_customer_ruc")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.customerFiscalNumber}  
                        </label>
                    </Col>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("default_currency")} 
                        </label>
                        <br></br>
                        <label>
                            {creditNote.currency}  
                        </label>
                    </Col>
                </Row>
                <Row style={{textAlign:'start'}}>
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("reason_for_return")}:  
                        </label>
                        <label style={{marginLeft:'5px'}}>
                            {creditNote.reasonForReturn}  
                        </label>
                    </Col>
                </Row>
                <Table className='table table-hover'>
                    <tbody>
                        <tr>
                            <th className='firstcell'>{i18n.translate("default_code")}</th>
                            <th className='cellgroup'>{i18n.translate("description")}</th>
                            <th className='cellgroup'>{i18n.translate("storage_price")}</th>
                            <th className='cellgroup'>{i18n.translate("default_amount")}</th>
                            <th className='cellgroup'>{i18n.translate("default_iva")}</th>
                            <th className='cellgroup'>{i18n.translate("vat_description")}</th>
                            <th className='cellgroup'>{i18n.translate("vat_taxable_proportion")}</th>
                        </tr>
                        {creditNote.detail.map((det) =>{
                            return(
                                <tr key={det.code}>
                                    <td>
                                        {det.code} 
                                    </td>
                                    <td>
                                        {det.description} 
                                    </td>
                                    <td>
                                        {det.price} 
                                    </td>
                                    <td>
                                        {det.amount} 
                                    </td>
                                    <td>
                                        {det.vat} 
                                    </td>
                                    <td>
                                        {det.vatDescription} 
                                    </td>
                                    <td>
                                        {det.vatTaxableProportion} 
                                    </td>
                                </tr>
                            )
                        })} 
                    </tbody>
                </Table>
            </>
        )
    } 

    render(){
        return (
            <>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover></ToastContainer>
                {this.state.modalContent}
                {this.state.modalContent2}
                <nav className='navbar'>
                    <Row style={{ width: '60%'}} className="contentInput">
                        {this.state.selectOrgType} 
                        <Col sm={12} md={4} lg={4} xl={4}>
                            <label style={{display: "block"}}>{i18n.translate("date")}</label>
                            <div>
                                <DateRangePicker
                                    initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate }}
                                    onCallback={this.handleCallback}
                                >
                                    <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard" />
                                </DateRangePicker>
                                <input type="hidden" id="dateInInputStart"/>
                                <input type="hidden" id="dateInInputEnd"/>
                            </div>
                        </Col> 
                    </Row>
                    <div style={{ paddingTop: "22px",width: '40%' }}>
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.getAllCreditNote(true)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_search")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex">
                                <button className='buttonMzateSecondary' onClick={() => this.getAllCreditNote(false)}>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_show_all")}</span>
                                </button>
                            </div>
                    </div>
                </nav>
                <br/>
                <div className='bodyContent' id='bodyContent'>
                    <div id="divTitle">
                        <span className="title">{i18n.translate("credit_notes")}</span>
                    </div>
                    {this.state.spinner}
                    <Table className='table table-hover'>
                        <tbody>
                            <tr>
                                <th className='firstcell'>{i18n.translate("date_issue")}</th>
                                <th className='cellgroup'>{i18n.translate("invoice_customer_name")}</th>
                                <th className='cellgroup'>{i18n.translate("invoice_customer_ruc")}</th>
                                <th className='cellgroup'>{i18n.translate("default_user")}</th>
                                <th className='cellgroup'>{i18n.translate("invoice_total_exempt")}</th>
                                <th className='cellgroup'>{i18n.translate("invoice_vat_5")}</th>
                                <th className='cellgroup'>{i18n.translate("invoice_vat_10")}</th>
                                <th className='cellgroup'>{i18n.translate("net_total")}</th>
                            </tr>
                            {this.state.arrayCreditNote.map((creditNote) => {
                                return (
                                    <tr key={creditNote.id} className='cellOptions' 
                                    onClick={() => this.showCreditNote(creditNote)}>
                                        <td>
                                            {creditNote.dateIssue} 
                                        </td>
                                        <td>
                                            {creditNote.customerName} 
                                        </td>
                                        <td>
                                            {creditNote.customerFiscalNumber} 
                                        </td>
                                        <td>
                                            {creditNote.responsibleUser} 
                                        </td>
                                        <td>
                                            {creditNote.exemptTotal} 
                                        </td>
                                        <td>
                                            {creditNote.vat05Total} 
                                        </td>
                                        <td>
                                            {creditNote.vat10Total} 
                                        </td>
                                        <td>
                                            {creditNote.netTotal} 
                                        </td>
                                    </tr>
                                )
                            })} 
                        </tbody>
                    </Table>
                </div>
            </>
        )
    } 
}

export default CreditNoteIndex;