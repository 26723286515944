import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import RemoveContent from "../../img/remove_content.png";
import AddContent from "../../img/add_content.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import { DescriptionConfig, DescriptionGIE, NewGIE } from './DescriptionConfig';

const url = process.env.REACT_APP_API_URL;

class IntervalDayAndHourTemplate extends React.Component {
    constructor(props){
        const optionsHour = [
            { value: '00'},{ value: '01'},{ value: '02'},{ value: '03'},{ value: '04'},{ value: '05'},{ value: '06'},
            { value: '07'},{ value: '08'},{ value: '09'},{ value: '10'},{ value: '11'},{ value: '12'},{ value: '13'},
            { value: '14'},{ value: '15'},{ value: '16'},{ value: '17'},{ value: '18'},{ value: '19'},{ value: '20'},
            { value: '21'},{ value: '22'},{ value: '23'}
        ]
        const optionsMinute = [
            { value: "00"},{ value: "05"},{ value: "10"},{ value: "15"},{ value: "20"},{ value: "25"},{ value: "30"},
            { value: "35"},{ value: "40"},{ value: "45"},{ value: "50"},{ value: "55"}, 
        ]
        var checkDays = {"monday":false,"tuesday":false,"wednesday":false,"thursday":false,"friday":false,"saturday":false,"sunday":false};
        var valuesToUpdate = [];
        var valuesToShow = [];
        var daysToShow = [];
        var intervals, daysAndHours, daysToSplit, hoursToSplit, days, hours;
        var minutesHours = [];
        var minutesHoursToCheck = [];
        var minuteInterval = [];
        super(props);
        this.state = {
            valuesToUpdate: [],
            valuesToShow: [],
            form:props.array,
            detail: props.det,
            checkDays: checkDays,
            optionsHour: optionsHour,
            optionsMinute: optionsMinute
        } 
        if(this.state.detail){
            intervals = this.state.detail.value.split("|");
            
        }else{
            {this.state.form.detail.map((elem) =>{
                if(elem.type == 0){
                    intervals = elem.value.split("|");
                    
                }
            })}
        }  
        for(var i = 0; i < intervals.length; i++){
            daysAndHours = intervals[i].split(";");
            daysToSplit = daysAndHours[0];
            hoursToSplit = daysAndHours[1];
            days = daysToSplit.split(",");
            hours = hoursToSplit.split("-");
            for(var j = 0; j < days.length; j++){
                if(days[j] == "1"){
                    checkDays.monday = true;
                    daysToShow.push(i18n.translate("monday"));
                }else if(days[j] == "2"){
                    checkDays.tuesday = true;
                    daysToShow.push(i18n.translate("tuesday"));
                }else if(days[j] == "3"){
                    checkDays.wednesday = true;
                    daysToShow.push(i18n.translate("wednesday"));
                }else if(days[j] == "4"){
                    checkDays.thursday = true;
                    daysToShow.push(i18n.translate("thursday"));
                }else if(days[j] == "5"){
                    checkDays.friday = true;
                    daysToShow.push(i18n.translate("friday"));
                }else if(days[j] == "6"){
                    checkDays.saturday = true;
                    daysToShow.push(i18n.translate("saturday"));
                }else if(days[j] == "7"){
                    checkDays.sunday = true;
                    daysToShow.push(i18n.translate("sunday"));
                }
            }
            var j = 0;
            while(j <= hours.length/2){
                minutesHours.push(hours[j]+"-"+hours[j+1]);
                minutesHoursToCheck.push(hours[j]);
                minutesHoursToCheck.push(hours[j+1]);
                j = j + 2;
            }
            for(var j = 0; j < minutesHoursToCheck.length; j++){
                var d = minutesHoursToCheck[j].split(":");
                for(var k = 0; k < d.length/2; k++){
                    minuteInterval.push({"hour":d[k],"minute":d[k+1]})
                }
            }
            minuteInterval = minuteInterval.reverse();
            minuteInterval.push(checkDays);
            checkDays = {"monday":false,"tuesday":false,"wednesday":false,"thursday":false,"friday":false,"saturday":false,"sunday":false};
            valuesToShow.push(daysToShow + minutesHours);
            valuesToUpdate.push(minuteInterval);
            daysToShow = [];
            minutesHours = [];
            minutesHoursToCheck = [];
            minuteInterval = [];
        } 
        this.state.valuesToShow = valuesToShow;
        for(var i = 0; i < valuesToUpdate.length; i++){
            valuesToUpdate[i].reverse();
        }
        this.state.valuesToUpdate = valuesToUpdate
    }
   
    addOption = () => {
        var weekToAdd = [];
        var checkDays = {"monday":false,"tuesday":false,"wednesday":false,"thursday":false,"friday":false,"saturday":false,"sunday":false};
        var hourMinute = {"hour": "07","minute": "00"}
        var hourMinute2 = {"hour": "19","minute": "00"}
        weekToAdd.push(checkDays);
        weekToAdd.push(hourMinute);
        weekToAdd.push(hourMinute2);
        this.state.valuesToUpdate.push(weekToAdd);
        var newWeek = this.state.valuesToUpdate;
        this.setState({valuesToUpdate:newWeek});
    }

    removeOption = (index) => {
        var week = []
        {this.state.valuesToUpdate.map((val,i)=>{
            if(i != index){
                week.push(val);
            }
        })}
        this.setState({valuesToUpdate:week})
    }

    checkTheDay = (index,wkItemIndex,event) => {
         var values = this.state.valuesToUpdate[index][wkItemIndex];
         Object.keys(values).forEach((e) => {
             if(e == event.target.name){
                 if(event.target.value == true || event.target.value == "true"){
                     if(e == "monday"){
                         values.monday = false;
                     }else if(e == "tuesday"){
                         values.tuesday = false;
                     }else if(e == "wednesday"){
                         values.wednesday = false;
                     }else if(e == "thursday"){
                         values.thursday = false;
                     }else if(e == "friday"){
                         values.friday = false;
                     }else if(e == "saturday"){
                         values.saturday = false;
                     }else if(e == "sunday"){
                         values.sunday = false;
                     }
                 }else{
                     if(e == "monday"){
                         values.monday = true;
                     }else if(e == "tuesday"){
                         values.tuesday = true;
                     }else if(e == "wednesday"){
                         values.wednesday = true;
                     }else if(e == "thursday"){
                         values.thursday = true;
                     }else if(e == "friday"){
                         values.friday = true;
                     }else if(e == "saturday"){
                         values.saturday = true;
                     }else if(e == "sunday"){
                         values.sunday = true;
                     }
                 }
             }
         });
        
        var newWeek = this.state.valuesToUpdate;
        this.setState({valuesToUpdate:newWeek});
        this.compareTheDays(index,wkItemIndex,event)
    }

    compareTheDays = (index,wkItemIndex,event) => {
        var val;
        var values = this.state.valuesToUpdate;
        {values.map((week,f)=>{
            if(f != index){
                val = week[wkItemIndex] 
                Object.keys(val).forEach((e) => {
                    if(e == event.target.name){
                        if(val[e] == true || val[e] == "true"){
                            if(e == "monday"){
                                val.monday = false;
                            }else if(e == "tuesday"){
                                val.tuesday = false;
                            }else if(e == "wednesday"){
                                val.wednesday = false;
                            }else if(e == "thursday"){
                                val.thursday = false;
                            }else if(e == "friday"){
                                val.friday = false;
                            }else if(e == "saturday"){
                                val.saturday = false;
                            }else if(e == "sunday"){
                                val.sunday = false;
                            }
                        }
                    } 
                });
            } 
        })} 
        var newWeek = values;
        this.setState({valuesToUpdate:newWeek}); 
    } 


    render(){
        return(
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <DescriptionConfig keyValue={this.state.form.key}/>
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("current_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        {this.state.valuesToShow.map((week) =>{
                            return(
                                <label style={{display:'block'}}>{week}</label>
                            )
                        })}
                    </div>
                </div>
                {this.state.form.update != false &&
                    <DescriptionGIE type={this.state.detail.type}/>
                }
                {this.state.form.update == false && 
                    <NewGIE/>
                }
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("new_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9 arrayStringNewValues'>
                        <div class='table-responsive'>
                            <table class='inJQueryAlerts table-sm table-borderless reducedSize'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class='weekday'>{i18n.translate("monday")}</th>
                                        <th class='weekday'>{i18n.translate("tuesday")}</th>
                                        <th class='weekday'>{i18n.translate("wednesday")}</th>
                                        <th class='weekday'>{i18n.translate("thursday")}</th>
                                        <th class='weekday'>{i18n.translate("friday")}</th>
                                        <th class='weekday'>{i18n.translate("saturday")}</th>
                                        <th class='weekday'>{i18n.translate("sunday")}</th>
                                        <th class='weekday'>{i18n.translate("default_schedule")}</th>
                                        <th class='button'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                        {this.state.valuesToUpdate.map((wk, index)=>{
                                            return(
                                                <tr class='trWeek'>
                                                    <td class='button'>
                                                        <img className='removeContent' onClick={() => this.removeOption(index)} src={RemoveContent} width="20px" height="20px"/>
                                                    </td>
                                                    {wk.map(((w,wkItemIndex)=>{
                                                        return(
                                                            <>
                                                            {wkItemIndex == 0 &&
                                                                <>
                                                                    <td class={'weekDay-'+index} name={index}>
                                                                        <input onClick={(event) => this.checkTheDay(index,wkItemIndex,event)} name="monday" type="checkbox" checked={w.monday}  value={w.monday}/>
                                                                    </td>
                                                                    <td class={'weekDay-'+index} name={index}>
                                                                        <input onClick={(event) => this.checkTheDay(index,wkItemIndex,event)} name="tuesday" type="checkbox" checked={w.tuesday} value={w.tuesday}/> 
                                                                    </td>
                                                                    <td class={'weekDay-'+index} name={index}>
                                                                        <input onClick={(event) => this.checkTheDay(index,wkItemIndex,event)} name="wednesday" type="checkbox" checked={w.wednesday} value={w.wednesday}/> 
                                                                    </td>
                                                                    <td class={'weekDay-'+index} name={index}>
                                                                        <input onClick={(event) => this.checkTheDay(index,wkItemIndex,event)} name="thursday" type="checkbox" checked={w.thursday} value={w.thursday}/> 
                                                                    </td>
                                                                    <td class={'weekDay-'+index} name={index}>
                                                                        <input onClick={(event) => this.checkTheDay(index,wkItemIndex,event)} name="friday" type="checkbox" checked={w.friday} value={w.friday}/> 
                                                                    </td>
                                                                    <td class={'weekDay-'+index} name={index}>
                                                                        <input onClick={(event) => this.checkTheDay(index,wkItemIndex,event)} name="saturday" type="checkbox" checked={w.saturday} value={w.saturday}/> 
                                                                    </td>
                                                                    <td class={'weekDay-'+index} name={index}>
                                                                        <input onClick={(event) => this.checkTheDay(index,wkItemIndex,event)} name="sunday" type="checkbox" checked={w.sunday} value={w.sunday}/> 
                                                                    </td>
                                                                </>
                                                            }
                                                            {wkItemIndex > 0 &&
                                                                <td class={'timeInterval-'+index} style={{display:'inline-flex'}} name={index}>
                                                                    <select class='form-select selectStandard newValueHour'>
                                                                        {this.state.optionsHour.map((h)=>{
                                                                            return(
                                                                                <>
                                                                                    {w.hour == h.value ? <option value={h.value} selected>{h.value}</option>
                                                                                    : <option value={h.value}>{h.value}</option>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    <select class='form-select selectStandard newValueMinute'>
                                                                        {this.state.optionsMinute.map((m)=>{
                                                                            return(
                                                                                <>
                                                                                    {w.minute == m.value ? <option value={m.value} selected>{m.value}</option>
                                                                                    : <option value={m.value}>{m.value}</option>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </td>
                                                            }
                                                            </>
                                                        )
                                                    }))}
                                                </tr>
                                            )
                                        })}
                                        <div>
                                            <img onClick={this.addOption} className='addContent' src={AddContent} width="30px"/>
                                        </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IntervalDayAndHourTemplate;