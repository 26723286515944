import React from "react";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";

export const SidebarDataTasks = [
    {
        title: "tasks_title",
        path: "/Tareas/tareas",
        icon: <FaIcons.FaMoneyCheck />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
        title: "settings_title",
        path: "/Tareas/configuraciones",
        icon: <FaIcons.FaCog />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
]