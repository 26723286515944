import React from "react";
import moment from 'moment';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';
import {  Modal, ModalBody, ModalFooter, ModalHeader, Row, Col} from 'reactstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import TimelineCalendar from "./TimelineCalendar";
import MapWrapper from "./MapWrapper";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getDateTimeForShow, setBackgroundToTask, prepareDateAndTime, 
    BooleanSelect, ImgReportTask, toastFunction, convertEstimatedDuration } from "./getTaskFunctions";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../img/independent/logo.png";
import logoCast from "../../img/cast_mzate/logo.png";
import DefaultSpinner from "../../components/DefaultSpinner";
import 'moment/locale/es';
import SelectGIE from "../../components/SelectGIE";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import DefaultModal from "../../components/DefaultModal";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { MultiSelect } from "react-multi-select-component";
import DefaultOfficeCustomerInfo from "../../components/DefaultOfficeCustomerInfo";

const url = process.env.REACT_APP_API_URL;

class TaskIndex extends React.Component {
    constructor(props) {
        moment.locale(props.language);
        const groups = [
            { id: 1, title: 'user 1',height: 100}, 
            { id: 2, title: 'user 2',height: 100},
            { id: 3, title: 'user 3',height: 100}, 
            { id: 4, title: 'user 4',height: 100}
        ]
        const items = [
        {
            id: 1,
            group: 2,
            title: 'Tarea 1',
            start_time: moment().add(-0, 'hour'),
            end_time: moment().add(3, 'hour'),
            canMove: true,
            canResize: true,
            canChangeGroup: true,
            itemProps: {
                // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
                "status":3,
                "resonsibleUsername":"user2",
                "latitude":-25.318231509727468,
                "longitude": -57.560167923184814,
                "taskToDo":"task1",
                "type":"Mantenimiento",
                "duration": "1 hora",
                "description": "Descripcion tarea",
                'aria-hidden': true,
                onDoubleClick: () => { console.log('You clicked double!') },
                className: 'weekend',
                style: {
                  background: '#1B9CD9',
                  borderRadius: '5px'
                }
              }
        }
        ]
        
        super(props);
        this.state={
            groups: groups,
            items: items,
            modalShowTask: false,
            modalTask:false,
            modalConflict: false,
            modalReport: false,
            modalConfirmDrag:false,
            modalConfirmDelete: false,
            component: "",
            isMap:false,
            responsible: [],
            responsibleSearch: [],
            responsibleSearchDefaultValue: [], 
            responsibleSelected:[],
            responsibleSelectedLabel: [],
            responsibleLabel:"",
            responsibleValue:"",
            state: [],
            stateSelected:"",
            typeForShow:"",
            description:"",
            type: [],
            typeSelected:"",
            typeSelectedModal:"",
            taskToDo: [],
            errorsList: [],
            conflictsList: [],
            modalConflictTitle: "",
            dateStartToEdit:"",
            model: "",
            dateToShow: "",
            isSave: true,
            typeToChange:[],
            dateRange:[],
            offices: [], 
            required_start_photo: "",
            required_end_photo: "",
            required_signature: "",
            standard_duration: "",
            service: {
                isSupportImpersonation: false,
                assignedId: 0,
                key: "",
                type: 0,
                functionalityGroup: "task",
            },
            startDate: moment().add(-12, 'hour'),
            endDate: moment().add(12, 'hour'),
            instanceType:"",
            instanceTypeDescription:"",
            independent:"",
            castMzate:"",
            spinner:"",
            standardConfigValues: false,
            defaulValueConfigsModal:false,
            optionDefaulValueConfigs:null,
            customerOfficeToShow:"",
            modalContent:"",
            dateDrag:"",
            timeDrag:"",
            taskDetail: [],
            userReport: "",
            dateReport: "",
            dateStartResume: "",
            dateEndResume: "",
            durationResume: "",
            stageForFilter: [0],
            taskDetailState: "",
            typeToSearch: [],
            estimatedDuration:"",
            filterSubStatusEnding: 0,
            filterSubStatusDelayedStart: 0,   
        };
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment().add(-12, 'hour'))));
        this.state.dateRange.push(this.dateRangeForSearh(new Date(moment().add(12, 'hour'))));
    }

    componentDidMount() {

        axios.get(url+"globalConfig.json").then(response => {
            this.setState({
                instanceType:response.data.instanceType,
                instanceTypeDescription:response.data.instanceTypeDescription,
                independent:response.data.independent,
                castMzate:response.data.castMzate,
                spinner:<DefaultSpinner instanceType={response.data.instanceType} 
                independent={response.data.independent}/>
            });
        })

        this.getTasks();
        
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbar").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 40;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        
        window.addEventListener('keypress', e => {
            if(e.key === 'Enter'){
                this.searchTasks();
            }
        });

    }

    getUserPreference = () => {
        DefaultAjax('').get("/userPreference?key=taskPopUpLegend&defaultValue=true&userId="+document.querySelector("#userId").value)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result){
                    document.querySelectorAll(".popup")[0].style.display = 'block';
                } 
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    handleDate = (date) => {
        this.setState({dateStartToEdit:date})
    }

    getTasks = () => {

        if(document.querySelector("#token").value === undefined || document.querySelector("#token").value === "" || document.querySelector("#token").value == null){
            let currentUrl = window.location.href;
            let params = currentUrl.split("?token=");
            let token = decodeURIComponent(params[1]);
            document.querySelector("#token").value = token;
        } 
        
        let officesToEdit = [];

        DefaultAjax('').get("task/offices?filter=")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                let customerOffice = "";
                for (let row of result) {
                    customerOffice = row.customerName;
                    if(row.customerAlias){
                        customerOffice += " - "+row.customerAlias;
                    } 
                    customerOffice += " - "+i18n.translate("office_type_"+row.officeType);
                    
                    if(row.officeName){
                        customerOffice += " - "+row.officeName;
                    } 
                    officesToEdit.push({"value":row.officeReplicaId,"label":customerOffice})
                }
                this.setState({offices:officesToEdit})
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });


        let responsibleToEdit = [];
        let userResponsible = [];

        let responsible = []; 

        DefaultAjax('').get("orgNode/eonList?moduleCode=VISITS")
        .then((res) => {
            if(res){
                let usersList = JSON.parse(res.data);
                for (let row of usersList) {
                    responsible.push({"value":row.username,"label":row.description});
                    responsibleToEdit.push({"value":row.username,"label":row.description});
                    userResponsible.push({"id":row.username,"title":row.description,
                        "height":150})
                }
                this.setState({
                    responsible:responsibleToEdit,
                    responsibleSearch:responsible
                });
                if(this.state.responsibleSearchDefaultValue.length == 0){
                    this.setState({responsibleSearchDefaultValue: responsible})
                } 
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

        this.setState({groups:userResponsible});

        let state = [];
        state.push({"value":"","label":i18n.translate("default_all")});

        DefaultAjax('').get("companySetting/companySettings?isSupportImpersonation=false&assignedId=&key=TASK_STATUS&type=3&functionalityGroup=tasks")
        .then((res) => {
            if(res){
                let config = JSON.parse(res.data);
                let configState;
                for(let row of config[0].detail){
                    configState = JSON.parse(row.value);
                    for(let configValue of configState){
                        state.push({"value":configValue,"label":configValue});
                    } 
                }
                this.setState({
                    state:state 
                });
                if(this.state.stateSelected == ""){
                    this.setState({
                        stateSelected: state[0] 
                    });
                }  
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

        let typeToEdit = []; 
        let typeToChange = [];

        let type = [];
        let typeToSearch = []; 

        DefaultAjax('').get("companySetting/companySettings?isSupportImpersonation=false&assignedId=&key=TASK_TYPE&type=3&functionalityGroup=tasks")
        .then((res) => {
            if(res){
                let config = JSON.parse(res.data);
                let configType;
                typeToSearch.push({"value":"","label":i18n.translate("default_all")});
                for(let row of config[0].detail){
                    configType = JSON.parse(row.value);
                    Object.keys(configType).forEach((e) => {
                        typeToChange.push(configType[e]);
                        type.push({"value":configType[e].type,"label":configType[e].type});
                        typeToSearch.push({"value":configType[e].type,"label":configType[e].type});
                        typeToEdit.push({"value":configType[e].type,"label":configType[e].type});
                    })
                }
                this.setState({
                    type:type,
                    typeToSearch:typeToSearch
                });
                if(this.state.typeSelected == ""){
                    this.setState({
                        typeSelected: typeToSearch[0] 
                    });
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

        this.setResponibleItems(userResponsible,officesToEdit,responsibleToEdit,typeToEdit,typeToChange);
    }

    setResponibleItems = (userResponsible,officesToEdit,responsibleToEdit,typeToEdit,typeToChange) => {

        this.setState({
            modalConfirmDrag:false,
            modalConflict:false,
            responsibleValue:"",
            responsibleLabel:"",
            items:[],
            component:"",
            model:""
        });
        
        let items = []; 
        let flagResponsible = false;
        let existNotReponsible = false;

        document.querySelector("#dateInInputStart").value = this.state.dateRange[0];
        document.querySelector("#dateInInputEnd").value = this.state.dateRange[1];

        let params = {

        };

        params.max = "15";
        params.pageNumber = "0";
        params.responsibles = JSON.stringify(this.state.responsibleSelected);
        params.taskToDo = "";
        params.status = this.state.stateSelected.value;
        params.type = this.state.typeSelected.value;
        params.officeFilter = "";
        params.startDate = this.state.dateRange[0];
        params.endDate = this.state.dateRange[1];
        params.stage = JSON.stringify(this.state.stageForFilter);

        DefaultAjax('').get("task/tasks?paramsJson="+encodeURIComponent(JSON.stringify(params)))
        .then((res) => { 
            if(res){
                let result = JSON.parse(res.data);
                for(var i = 0; i < result.tasksList.length; i++){
                    if(result.tasksList[i].responsibleUsername === "" || result.tasksList[i].responsibleUsername === undefined){
                        result.tasksList[i].responsibleUsername = null;
                    } 
                    items.push({"id":result.tasksList[i].id,"group":result.tasksList[i].responsibleUsername,
                    "title":result.tasksList[i].description,"start_time":moment(result.tasksList[i].plannedDateStart),
                    "end_time":moment(result.tasksList[i].plannedDateEnd),"canMove":true,"canResize":false,"canChangeGroup":true,
                    "itemProps":result.tasksList[i]
                    });
                    items[i].itemProps.className = 'weekend';
                    items[i].itemProps.ariaHidden = true;
                    items[i].itemProps.style = {"background":'#1B9CD9',"borderRadius":'5px'};
                    if(items[i].itemProps.responsibleFullname == null || items[i].itemProps.responsibleFullname === "" || items[i].itemProps.responsibleFullname === undefined){
                        flagResponsible = true;
                    } 
                } 
                if(flagResponsible){
                    for(i = 0; i < userResponsible.length; i++){
                        if(userResponsible[i].id == null){
                            existNotReponsible = true;
                        } 
                    } 
                    if(existNotReponsible === false){
                        userResponsible.unshift({"id":null,"title":i18n.translate("without_responsible"),
                        "height":150
                        })
                    } 
                } 
                this.setState({items:setBackgroundToTask(items)});
                
                if(items.length === 0){
                    items = this.state.items;
                } 
                if(responsibleToEdit.length === 0){
                    responsibleToEdit = this.state.groups;
                } 
                if(typeToChange){
                    this.setState({typeToChange:typeToChange});
                } 
                this.setState({component:""});
                    setTimeout(()=> {
                        this.setState({spinner:""})
                        if(this.state.isMap){
                            this.prepareToShowMap(items);
                        }else{ 
                            this.setState({component:<TimelineCalendar startDate={this.state.startDate} endDate={this.state.endDate}
                                groups={userResponsible} items={items} service={this.state.service} offices={officesToEdit}
                                responsible={responsibleToEdit} type={typeToEdit} typeToChange={typeToChange} 
                                onClick={this.updateCreateTask} handleDate={this.handleDate} handleChangeSelect={this.handleChangeSelect}
                                newTask={this.newTask} showTask={this.showTask} dragTask={this.dragTask} handleCallback={this.handleCallback}
                                getItemsAfterScroll={this.getItemsAfterScroll} updateStageValue={this.updateStageValue} 
                                stageForFilter={this.state.stageForFilter} filterSubStatusEnding={this.state.filterSubStatusEnding} 
                                filterSubStatusDelayedStart={this.state.filterSubStatusDelayedStart} />});
                        } 
                    }, 100);
                    setTimeout(()=> {
                        this.setLegendFilter(); 
                        this.getUserPreference();
                    }, 150)
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    searchTasks = () => {
        if(this.state.responsibleSelected.length == 0){
            this.getTasks();
        }else{
            let groupToSearc = []; 
            for(let i = 0; i < this.state.responsibleSearchDefaultValue.length; i++){
                groupToSearc.push({"id": this.state.responsibleSearchDefaultValue[i].value, "title": this.state.responsibleSearchDefaultValue[i].label,"height":150})
            } 
            this.setResponibleItems(groupToSearc,this.state.offices,this.state.responsible,this.state.type,this.state.typeToChange);
            
        } 
    }

    prepareToShowMap = (items) => {
        for(let row of items){
            if(row.start_time){
                row.startDateForShow = getDateTimeForShow(row.start_time);
            }
            if(row.end_time._d){
                row.endDateForShow = getDateTimeForShow(row.end_time);
            }
        }
        this.setState({component:<MapWrapper items={items} showTask={this.showTask}/>});
    }

    newTask = (userId, time, e) => {

        let dateToCompare,date;

        if(time !== undefined){
            dateToCompare = new Date();
            date = new Date(time);
        }else{
            date = new Date();
        }  
        if(date !== undefined && date < dateToCompare){
            toastFunction(i18n.translate("date_time_must_be_greater"),"warning");
        }else{
            this.setState({
                isSave:true,
                required_start_photo:"",
                required_end_photo:"",
                required_signature:"",
                standard_duration:"",
                description:"",
                modalTask:true
            });
            this.handleDate(date)
            this.setState({dateStartToEdit:date});

            for(let row of this.state.groups){
                if(userId === row.id){

                    this.setState({responsibleValue:userId});
                    this.setState({responsibleLabel:row.title});
                }
            }
        }
    }

    closeModal = () => {
        this.setState({
            modalTask:false,
            typeSelectedModal:"",
            standardConfigValues:false
        });
    }

    closeModalErrorConflict = () => {
        this.setState({modalConflict:false});
    }

    updateCreateTask = () => {
        let model,type,id,officeReplicaId,status;
        type = this.prepareTypeTaskMap();
        if(this.state.isSave){
            id = null;
            officeReplicaId = this.state.officeReplicaId;
            status = "Creada";
        }else{
            id = this.state.itemShow.id;
            officeReplicaId = this.state.itemShow.itemProps.officeReplicaId;
            status = this.state.stateForShow;
        }
        if(this.state.model === ""){
            this.setState({errorsList:[]});
            this.setState({conflictsList: []})
            let arrayDateTime = prepareDateAndTime(new Date(this.state.dateStartToEdit));
            arrayDateTime = arrayDateTime.split(" / ");
             
            model = {
                plannedDate: arrayDateTime[0],
                plannedHour: arrayDateTime[1],
                isSave: this.state.isSave,
                duration: type.settings.standard_duration.value,
                id: id,
                description: this.state.description,
                responsibleUsername: this.state.responsibleValue,
                status: status,
                officeReplicaId: officeReplicaId,
                type: JSON.stringify(type),
                taskToDo: JSON.stringify(this.state.taskToDo)
            } 
        }else{
            model = this.state.model;
            let arrayDateTime = prepareDateAndTime(new Date(this.state.dateStartToEdit));
            arrayDateTime = arrayDateTime.split(" / ");
            model.plannedDate = arrayDateTime[0];
            model.plannedHour = arrayDateTime[1];
            model.isSave = this.state.isSave;
            model.duration = type.settings.standard_duration.value;
            model.id = id;
            model.description = this.state.description;
            model.responsibleUsername = this.state.responsibleValue;
            model.status = status;
            model.officeReplicaId = officeReplicaId;
            model.type = JSON.stringify(type);
            model.taskToDo = JSON.stringify(this.state.taskToDo)
        }

        this.setState({model:model});

        if(model.officeReplicaId === "" || model.officeReplicaId === undefined){
            toastFunction(i18n.translate("you_must_select_a_customer"),"warning");
        }else if(type.type === "" || type.type === undefined){
            toastFunction(i18n.translate("you_must_select_the_type_of_task"),"warning");
        }else if(model.description === "" || model.description === undefined || model.description == null){
            toastFunction(i18n.translate("description_is_required"),"warning");
        }else if(this.state.responsibleValue == "" || this.state.responsibleValue == undefined){
            this.setState({modalContent:""});
            let modalContent;
            modalContent = <div>{i18n.translate("task_without_responsible_confirm")}</div>;
            setTimeout(()=> {
                let buttons = [{text: i18n.translate("default_confirm"), function: this.confirmTaskWhitoutResponsible},
                {text: i18n.translate("cancel"), function: ""}];  
                this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("default_confirm")}
                content={modalContent} buttons={buttons}/>})
            }, 10); 
        }else{
            this.confirmTaskWhitoutResponsible(model);
        } 
    }

    confirmTaskWhitoutResponsible = (model) => {
        if(!model.officeReplicaId){
            model = this.state.model;
        } 
        this.setState({modalContent:""});
        let dateToCompare = new Date();
        if(new Date(model.plannedDate+"T"+model.plannedHour) < dateToCompare){
            toastFunction(i18n.translate("date_time_must_be_greater"),"warning");
        }else{
            this.executeValidate(model);
        }
    } 

    prepareTypeTaskMap = () => {
        let type = {"type":this.state.typeForShow,"settings":{
            "required_end_photo": {"value": this.state.required_end_photo,"type":"BOOLEAN"},
            "required_signature": {"value":this.state.required_signature,"type":"BOOLEAN"},
            "required_start_photo": {"value":this.state.required_start_photo,"type":"BOOLEAN"},
            "standard_duration": {"value":this.state.standard_duration,"type":"numeric"}
        }}
        return type;
    }

    executeValidate = (model) => {
        DefaultAjax('').post("task/validate?isSave="+this.state.isSave,model)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result.errorsList.length > 0){
                    this.setState({modalConflictTitle: i18n.translate("default_errors")});
                    this.setState({modalConflict:true});
                    this.setState({errorsList:result.errorsList});
                }else if(result.conflictsList.length > 0){
                    this.setState({modalConflictTitle: i18n.translate("default_conflicts")});
                    this.setState({modalConflict:true});
                    this.setState({conflictsList:result.conflictsList})
                }else{
                    if(this.state.isSave){
                        this.executeCreate(model);
                    }else{
                        this.executeUpdate(model);
                    }
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }  

    updateStageValue = (stage, filterSubStatusDelayedStart,filterSubStatusEnding) => {
        this.setState({
            stageForFilter:stage,
            filterSubStatusDelayedStart:filterSubStatusDelayedStart,
            filterSubStatusEnding:filterSubStatusEnding
        });
    } 

    executeCreate = (task) => {

        if(task === undefined){
            task = this.state.model;
        }
        DefaultAjax('').post("task/task",task)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result.status === 0 || result.status === '0'){
                    this.closeModal();
                    toastFunction(i18n.translate("task_created"),"success");
                    this.setResponibleItems(this.state.groups,this.state.offices,this.state.responsible,this.state.type,this.state.typeToChange);
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    }

    executeUpdate = (task) => {
        var task = task;
        if(task === undefined){
            task = this.state.model;
        }

        DefaultAjax('').put("task/task",task)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                document.querySelector("#containerReport").style.display = "none";
                if(result.status == 0){
                    this.setState({
                        modalShowTask:false,
                        responsibleValue: "",
                        responsibleLabel: ""
                    });

                    toastFunction(i18n.translate("task_updated"),"success");
                    this.setResponibleItems(this.state.groups,this.state.offices,this.state.responsible,this.state.type,this.state.typeToChange);
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });

    }

    setLegendFilter = () => {
        {this.state.stageForFilter.map((stage) => {
            if(document.querySelector("#divTaskStatus-"+stage)){
                document.querySelector("#divTaskStatus-"+stage).classList.add("divTaskStatusInactive");
                if(stage == 1){
                    document.querySelector("#divTaskStatus-4").style.pointerEvents = "none";
                }else if(stage == 2){
                    document.querySelector("#divTaskStatus-3").style.pointerEvents = "none";
                }  
            } 
        })}
    } 

    changeView = () => {
        if(this.state.isMap){
            this.setState({isMap:false});
            this.setState({component:<TimelineCalendar groups={this.state.groups} startDate={this.state.startDate} 
                endDate={this.state.endDate} items={this.state.items} service={this.state.service} offices={this.state.offices}
                responsible={this.state.responsible} type={this.state.type} typeToChange={this.state.typeToChange}
                onClick={this.updateCreateTask} handleDate={this.handleDate} handleChangeSelect={this.handleChangeSelect}
                newTask={this.newTask} showTask={this.showTask} dragTask={this.dragTask} handleCallback={this.handleCallback}
                getItemsAfterScroll={this.getItemsAfterScroll} updateStageValue={this.updateStageValue}
                stageForFilter={this.state.stageForFilter} filterSubStatusEnding={this.state.filterSubStatusEnding} 
                filterSubStatusDelayedStart={this.state.filterSubStatusDelayedStart}/>})
            setTimeout(()=> {
                this.setLegendFilter();
            }, 150)
        }else{
            this.setState({isMap:true});
            for(let row of this.state.items){
                if(row.start_time){
                    row.startDateForShow = getDateTimeForShow(row.start_time);
                }
                if(row.end_time._d){
                    row.endDateForShow = getDateTimeForShow(row.end_time);
                }
            }
            this.setState({component:<MapWrapper items={this.state.items} showTask={this.showTask}/>});
            
        }
    }

    handleCallback = (start, end, label) => {
        this.setState({dateRange:""})
        let dateRange = []; 
        document.querySelector("#dateInInputStart").value = this.dateRangeForSearh(start._d);
        document.querySelector("#dateInInputEnd").value = this.dateRangeForSearh(end._d);
        dateRange.push(this.dateRangeForSearh(start._d));
        dateRange.push(this.dateRangeForSearh(end._d));
        this.setState({
            dateRange:dateRange,
            startDate: start._d,
            endDate:end._d
        });
    }

    dateRangeForSearh = (date) => {
        let startDate = new Date(date);
        let m = startDate.getMonth() + 1;
        let mes = (m < 10) ? '0' + m : m;
        let day = startDate.getDate();
        let dayForShow = (day < 10) ? '0' + day : day;
        startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
        return startDate;
    }

    handleChangeForm = e => {
        if(e.target.name === "description"){
            this.setState({description:e.target.value});
        }else if(e.target.name === "standard_duration"){
            this.setState({standard_duration:e.target.value});
            this.setState({standardConfigValues:true});
        }  
    }

    handleChangeConfig = e => {
        if(e.target.id === "required_start_photo"){
            this.setState({required_start_photo: e.target.value})
        }else if(e.target.id === "required_end_photo"){
            this.setState({required_end_photo: e.target.value})
        }else{
            this.setState({required_signature: e.target.value})
        }
        this.setState({standardConfigValues:true}); 
    } 

    reportsModal = () => {
        this.setState({dateRange:""})
        let dateRange = []; 
        dateRange.push(this.dateRangeForSearh(this.state.startDate));
        dateRange.push(this.dateRangeForSearh(this.state.endDate))
        this.setState({
            dateRange:dateRange,
            modalReport:true
        });
    }

    closeModalReport = () => {
        this.setState({
            modalReport:false,
            defaulValueConfigsModal:false,
            optionDefaulValueConfigs:null
        });
    }

    executeGenerateReport = () => {
        if(document.querySelector(".modalBody .selectType").value === ''){
            toastFunction(i18n.translate("select_org_node"),"warning");
        }else{
            let model = {
                dateStart: this.state.dateRange[0],
                dateEnd: this.state.dateRange[1],
                type: document.querySelector(".modalBody .selectType").value,
                user: document.querySelector(".modalBody #userSelected").value,
                gon: document.querySelector(".modalBody #groupSelected").value,
                ion: document.querySelector(".modalBody #profileSelected").value
            }
            if(document.querySelector(".modalBody .selectType").value === "1" && model.ion === ''){
                toastFunction(i18n.translate("select_org_node"),"warning");
            }else if(document.querySelector(".modalBody .selectType").value === "2" && model.gon === ''){
                toastFunction(i18n.translate("select_org_node"),"warning");
            }else{
                this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
                independent={document.querySelector("#independent").value}/>})
                axios({
                    method: 'GET',
                    url: url+"task/generateTaskReportFromAppWeb?reportDtoJson=" + encodeURI(JSON.stringify(model)),
                    headers: {'Authorization': document.querySelector("#token").value},
                    responseType: 'blob',
                }).then(response=>{      
                    this.setState({
                        spinner:""
                    });  
                    if(response.status === 200 || response.status === "200"){

                        const FileDownload = require('js-file-download');

                        toastFunction(i18n.translate("download_started"),"success");
                        this.closeModalReport();

                        FileDownload(response.data, 'reporte_de_tarea.xlsx');

                    }else{

                        toastFunction(i18n.translate("an_error_has_ocurred"),"error");   

                    }                                                                                                                                                                                                                                                 
                }).catch((error) =>{
                    this.setState({
                        modalContent:"",
                        spinner:"",
                        component:error.message
                    });
                    if (error.response) {
                        if(error.response.status === 403){
                            setTimeout(()=> { 
                                this.setState({modalContent:modalForbidden()});
                            }, 10);
                        } 
                    } 
                });
            }
        } 
    }

    changeConfigValue = (option) => {
        let newTaskToDo = [];
        let rowTypeTask = document.querySelectorAll(".rowsTypeTask");
        let arrayTypeTask = Array.from(rowTypeTask);
        arrayTypeTask.find(at => at.classList.remove("rowsTypeTask"));
        this.setState({typeForShow:option.value});
        {this.state.typeToChange.map((typeToChange) => {
            if(typeToChange.type == option.value){
                for(let row of typeToChange.settings.tasks_to_do.value){
                    newTaskToDo.push({"type":row,

                    "value":{"started":"false","finished":"false"} 
                    })
                }
                this.setState({
                    taskToDo:newTaskToDo,
                    required_start_photo:typeToChange.settings.required_start_photo.value,
                    required_end_photo:typeToChange.settings.required_end_photo.value,
                    required_signature:typeToChange.settings.required_signature.value,
                    standard_duration:typeToChange.settings.standard_duration.value
                }) 
            } 
        })} 
        this.setState({
            standardConfigValues:false,
            typeSelectedModal:{ label: option.value, value: option.value},
            optionDefaulValueConfigs: null,
            defaulValueConfigsModal:false
        });
    } 
     
    handleChangeSelect = (option,e) => {
        if(e.name === "type"){
            if(this.state.standardConfigValues){
                this.setState({
                    defaulValueConfigsModal:true,
                    optionDefaulValueConfigs:option
                });
            }else{
                this.changeConfigValue(option);
            } 
        }else if(e.name === "status"){
            this.setState({stateForShow:option.value})
        }else if(e.name === "responsible"){
            this.setState({responsibleValue:option.value});
            this.setState({responsibleLabel:option.label});
        }else if(e.name === "offices"){
            this.setState({officeReplicaId:option.value});
        }
    }

    showTask = (itemId, e, time) => {
        let Status = [];
        let dateToShow = "";
        for(let row of this.state.items){
            if(row.id === itemId){
                this.setState({
                    isSave:false,
                    customerOfficeToShow: <DefaultOfficeCustomerInfo officeCustomerMap={row.itemProps} />
                });
                let s = JSON.parse(row.itemProps.statusSetting);
                this.setState({itemShow:row});
                dateToShow = prepareDateAndTime(row.start_time);

                let dateReportSplited = row.itemProps.plannedDateStart;
                dateReportSplited = dateReportSplited.split("T");

                this.setState({
                    dateToShow:dateToShow,
                    dateReport: prepareDateAndTime(new Date()),
                    userReport: document.querySelector("#userFullname").value,
                    estimatedDuration : convertEstimatedDuration(row.itemProps.duration),
                    reportName: row.itemProps.customerName+"_"+dateReportSplited[0] 
                });
                for(let j = 0; j < s.length; j++){
                    Status.push({"value":s[j],"label":s[j]});
                } 
                this.setState({status:Status})
                let type = JSON.parse(row.itemProps.type);

                let values = type.settings;
                Object.keys(values).forEach((e) => {
                    if(e === "required_start_photo"){
                        this.setState({required_start_photo:values[e].value});
                    }else if(e === "required_end_photo"){
                        this.setState({required_end_photo:values[e].value});
                    }else if(e === "required_signature"){
                        this.setState({required_signature:values[e].value});
                    }else{
                        this.setState({standard_duration:values[e].value});
                    } 
                })
                let taskToDo = JSON.parse(row.itemProps.taskToDo);
                this.setState({
                    taskToDo:taskToDo,
                    description:row.itemProps.description,
                    typeForShow:type.type,
                    stateForShow:row.itemProps.status,
                    responsibleValue:row.itemProps.responsibleUsername,
                    responsibleLabel:row.itemProps.responsibleFullname,
                    dateStartToEdit:row.itemProps.plannedDateStart
                })
                let taskReplicaId = row.itemProps.replicaId;
                
                DefaultAjax('').get("task/task?replicaId="+encodeURIComponent(taskReplicaId))
                .then((res) => {
                    if(res){
                        let result = JSON.parse(res.data);
                        if(result){
                            
                            let dateStartResume,dateEndResume;
                            if(result.startDate){
                                dateStartResume = result.startDate;
                            }
                            if(result.endDate){
                                dateEndResume = result.endDate;
                            } 

                            this.setState({
                                taskDetail:result.detail,
                                modalShowTask:true
                            });

                            document.querySelector("#containerReport").style.display = "block";

                            {result.detail.map((det,index) =>{

                                if(index === 0){
                                    dateStartResume = det.startDate;
                                } 

                                dateEndResume = det.endDate;

                            })}

                            this.setState({
                                dateStartResume:dateStartResume,
                                dateEndResume:dateEndResume,
                                durationResume:result.timeUsed
                            }); 

                            setTimeout(()=> { 
                                let heightAbstract = document.querySelectorAll(".modalBody.modal-body")[0].clientHeight - 70;
                                document.querySelector("#detailDiv").style.height = heightAbstract+"px";
                            }, 100);

                        }
                    }
                }).catch((error) =>{
                    this.setState({
                        modalContent:"",
                        spinner:"",
                        component:error.message
                    });
                    if (error.response) {
                        if(error.response.status === 403){
                            setTimeout(()=> { 
                                this.setState({modalContent:modalForbidden()});
                            }, 10);
                        } 
                    }
                });
                break;
            }
        }
    } 

    changeYearsForDays = (date) => {
        let dateToReturn;
        let dateTimeSplited = date.split(" ");
        let dateSplited = dateTimeSplited[0].split("-");
        dateToReturn = dateSplited[2]+"-"+dateSplited[1]+"-"+dateSplited[0]+" "+dateTimeSplited[1];
        return dateToReturn
    } 

    closeModalShow = () => {
        this.setState({
            modalShowTask:false,
            responsibleValue: "",
            responsibleLabel: ""
        });
        document.querySelector("#containerReport").style.display = "none";
    }

    deleteTask = () => {
        this.setState({modalConfirmDelete: true});
    }

    closeModalConfirmDelete = () => {
        this.setState({modalConfirmDelete: false});
    }

    executeDeleteTask = () => {
        let model = {"id": this.state.itemShow.id};

        DefaultAjax('').delete("task/task?id="+this.state.itemShow.id)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result.errorsList.length > 0){
                    this.setState({modalConflictTitle: i18n.translate("default_errors")});
                    this.setState({modalConflict:true});
                    this.setState({errorsList:result.errorsList});
                }else if(result.status === 0 || result.status === "0"){
                    this.setState({modalConfirmDelete: false});
                    this.setState({
                        modalShowTask: false,
                        responsibleValue: "",
                        responsibleLabel: ""
                    });
                    toastFunction(i18n.translate("task_deleted"),"success");
                    this.setResponibleItems(this.state.groups,this.state.offices,this.state.responsible,this.state.type,this.state.typeToChange);
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }

    showHideDetailAbstract = (div) => {
        if(div === "detail"){
            document.querySelector("#detailDiv").style.display = "block";
            document.querySelector("#abstractDiv").style.display = "none";
            document.querySelector("#abstractOption").classList.remove("activeItem");
            document.querySelector("#detailOption").classList.add("activeItem");
        }else{
            document.querySelector("#detailDiv").style.display = "none";
            document.querySelector("#abstractDiv").style.display = "block";
            document.querySelector("#abstractOption").classList.add("activeItem");
            document.querySelector("#detailOption").classList.remove("activeItem");
        } 
    }

    showDetail = (id) => {
        let divDetail = document.querySelectorAll(".detailTask");
        let idSplit;
        for(let row of divDetail){
            if(row.id == document.querySelector("#detail"+id).id){
                if(document.querySelector("#detail"+id).style.display == "none"){

                    document.querySelector("#detail"+id).style.display = "block";
                    document.querySelector("#arrowDown"+id).style.display = "";
                    document.querySelector("#arrowRigth"+id).style.display = "none";
                }else{
                    document.querySelector("#detail"+id).style.display = "none";
                    document.querySelector("#arrowDown"+id).style.display = "none";
                    document.querySelector("#arrowRigth"+id).style.display = "";
                } 
            }else{
                row.style.display = "none";
                idSplit = row.id.split("detail");
                document.querySelector("#arrowDown"+idSplit[1]).style.display = "none";
                document.querySelector("#arrowRigth"+idSplit[1]).style.display = "";
            }
        }
    }

    generateReport = () => {
        this.setState({spinner:<DefaultSpinner instanceType={this.state.instanceType} 
            independent={this.state.independent}/>});
        const el = document.querySelector('#containerReport');
        html2canvas(el).then(canvas => {

            let heigthReport = el.clientHeight
            const dataURL = canvas.toDataURL('image/jpeg', 1.0);

            let imgWidth = 210;
            let pageHeight = 295;
            let imgHeight = canvas.height * imgWidth / canvas.width;

            const pdf = new jsPDF();

            let position = 0;

            let pdfWidth = pdf.internal.pageSize.getWidth() - 10;
            let pdfHeight = pdf.internal.pageSize.getHeight() - 10;
            let heightLeft = imgHeight;

            pdf.addImage(dataURL, 'JPEG', 5, position, pdfWidth, 0);

            heightLeft -= pageHeight;
            
            if(heigthReport > 864){
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(dataURL, 'JPEG', 5, position, pdfWidth, 0);
                    heightLeft -= pageHeight;
                }
            }

            this.setState({spinner:""});
            pdf.save(this.state.reportName+'.pdf');

        })
    }

    closeModalConfirm = () => {
        this.setState({
            modalConfirmDrag:false,
            responsibleValue: "",
            responsibleLabel: ""
        });
    }

    dragTask = (taskId, dragTime, userId) => {
        let dateToCompare = new Date();
        let date = new Date(dragTime);
        this.setState({dateStartToEdit:date})
        let items = this.state.items;
        if(date.getTime() < dateToCompare.getTime() || date < dateToCompare){
            toastFunction(i18n.translate("date_time_must_be_greater"),"warning");
        }else{
            for(let row of items){
                if(row.id == taskId){
                    if(this.state.groups[userId].id == undefined || this.state.groups[userId].id == null){
                        toastFunction(i18n.translate("must_assign_responsible"),"warning");
                    }else if(row.itemProps.startDate == null){
                        let dateTimeToSplit = this.state.itemShow.itemProps.plannedDateStr.split(" ");
                        let dateToSplit = dateTimeToSplit[0].split("-");
                        this.setState({
                            dateDrag: dateToSplit[2]+"-"+dateToSplit[1]+"-"+dateToSplit[0],
                            timeDrag: dateTimeToSplit[1] 
                        })
                        this.setState({modalConfirmDrag:true});
                        let arrayDateTime = prepareDateAndTime(date);
                        arrayDateTime = arrayDateTime.split(" / ");
                        let type = row.itemProps.type

                        let model = {
                            plannedDate: arrayDateTime[0],
                            plannedHour: arrayDateTime[1],
                            isSave: false,
                            duration: JSON.parse(type).settings.standard_duration.value,
                            id: this.state.itemShow.id,
                            description: row.itemProps.description,
                            responsibleUsername: this.state.groups[userId].id,
                            status: this.state.stateForShow,
                            officeReplicaId: row.itemProps.officeReplicaId,
                            type: JSON.stringify(type),
                            taskToDo: JSON.stringify(this.state.taskToDo)
                        } 
                        this.setState({
                            model:model,
                            responsibleValue:this.state.groups[userId].id,
                            description: row.itemProps.description,
                            officeReplicaId: row.itemProps.officeReplicaId,
                            standard_duration: JSON.parse(type).settings.standard_duration.value,
                            isSave:false,
                            required_end_photo: JSON.parse(type).settings.required_end_photo.value,
                            required_start_photo: JSON.parse(type).settings.required_start_photo.value,
                            required_signature: JSON.parse(type).settings.required_signature.value
                        });
                    }else{
                        toastFunction(i18n.translate("task_already_started"),"warning");
                    }
                    break;
                }
            }
        }
    } 

    getItemsAfterScroll = (items) => {
        this.setState({items:items})
    } 


    render(){
        const handleChange = (option) => {
            this.setState({responsibleSearchDefaultValue:option});
            let responsibleSelectedArray = []; 
            let responsibleSelectedArrayLabel = []; 
            for(let i = 0; i < option.length; i++){
                responsibleSelectedArray.push(option[i].value);
                responsibleSelectedArrayLabel.push(option[i].label)
            } 
            this.setState({responsibleSelected:responsibleSelectedArray});
            
            this.setState({responsibleSelectedLabel:option.label});
        };
        const handleChangeState = (option) => {
            this.setState({stateSelected:option});
        };
        const handleChangeType = (option) => {
            this.setState({typeSelected:option});
        }
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }

        const logoContainer = {
        background: 'darkgray',
        textAlign: "center",
        padding: "5px"
        };
        const textCenter ={
        textAlign: "center"
        } 
        const container ={
        width: "152mm"
        } 
        const textBlock = {
        display: 'block'
        };
        const labelRight = {
        float: "right"
        } 
            
        const bolder = {
        fontWeight: "bold"
        }
        const divImageLeft = {
        display: "inline-block",
        maxHeight:"168px",
        minWidth: "190px",
        minHeight:"168px",
        textAlign: "center"
        }  
        const divImageRiht = {
        display: "inline-block",
        maxHeight:"168px",
        minWidth: "190px",
        minHeight:"168px",
        textAlign: "center"
        } 
        const divImageRihtFirm = {
        display: "inline-block",
        float: "right",
        marginTop: "50px",
        width: "200px",
        maxHeight:"150px",
        textAlign:"center"
        } 
        const labelImage = {
        display: "block"
        } 
        const divRoot = {
        display: "flow-root",
        height: "650px", 
        }
        const divRoot2 = {
            display: "flow-root",
            height: "425px"
        }

        return(
            <>
                <br />
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true} 
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
                    {this.state.modalContent}
                    <nav className='navbar'>
                        <div className='contentInput row divIndex' style={{width: '60%'}}>
                            <div style={{ display: 'inline-block' }} className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <label style={{display: "block"}}>{i18n.translate("date")}</label>
                                <div>
                                    <DateRangePicker
                                        initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate }}
                                        onCallback={this.handleCallback}
                                    >
                                        <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard" />
                                    </DateRangePicker>
                                    <input type="hidden" id="dateInInputStart"/>
                                    <input type="hidden" id="dateInInputEnd"/>
                                </div>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <label>{i18n.translate("responsible")}</label>
                                <MultiSelect
                                    options={this.state.responsibleSearch}
                                    value={this.state.responsibleSearchDefaultValue}
                                    onChange={handleChange}
                                    overrideStrings={{
                                        selectSomeItems: i18n.translate("default_select"),
                                        allItemsAreSelected: i18n.translate("all_items_are_selected"),
                                        selectAll: i18n.translate("select_all"),
                                        search: i18n.translate("default_search"),
                                    }}
                                />
                                <input type="hidden" id="inputResponsibleSelected" value={this.state.responsibleSelected}/>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <label>{i18n.translate("state")}</label>
                                <Select onChange={handleChangeState} menuShouldScrollIntoView={false} options={this.state.state} 
                                placeholder={i18n.translate("default_select")} value={this.state.stateSelected}/>
                                <input type="hidden" id="inputStateSelected" value={this.state.stateSelected.value}/>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <label style={{display: "block"}}>{i18n.translate("default_type")}</label>
                                <Select onChange={handleChangeType} menuShouldScrollIntoView={false} options={this.state.typeToSearch}
                                placeholder={i18n.translate("default_select")} value={this.state.typeSelected}/>
                                <input type="hidden" id="inputTypeSelected" value={this.state.typeSelected.value}/>
                            </div>
                            {' '}
                        </div>
                        <div style={{ paddingTop: "22px",width: '40%' }}>
                            <div style={{ display: 'inline-block' }} className="divIndex" onClick={() => this.searchTasks()}>
                                <button className='buttonMzateSecondary'>
                                    <FaIcons.FaSearch />
                                    <span>{i18n.translate("default_search")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex" onClick={() => this.newTask()}>
                                <button className='buttonMzateSecondary'>
                                    <FaIcons.FaPlus />
                                    <span>{i18n.translate("newTask")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex" onClick={() => this.reportsModal()}>
                                <button className='buttonMzateSecondary'>
                                    <FaIcons.FaFile />
                                    <span>{i18n.translate("reports")}</span>
                                </button>
                            </div>
                            {' '}
                            <div style={{ display: 'inline-block' }} className="divIndex" onClick={() => this.changeView()}>
                                <button className='buttonMzateSecondary'>
                                    {this.state.isMap && <><FaIcons.FaTasks/><span>{i18n.translate("timeline")}</span></>}
                                    {!this.state.isMap && <><FaIcons.FaMap /><span>{i18n.translate("map")}</span></>}
                                </button>
                            </div>
                            {' '}
                        </div>
                    </nav>
                    <br />
                    <div className='bodyContent' id='bodyContent'>
                        {this.state.spinner}
                        {this.state.component}
                    </div>

                    <Modal isOpen={this.state.modalTask} size='lg'>
                        <ModalHeader className='modalHeader'>
                            {i18n.translate("newTask")}
                            <button className='close' data-dismiss="close" onClick={() => this.closeModal()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' >
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("default_client")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <Select onChange={this.handleChangeSelect} name="offices" menuShouldScrollIntoView={false} options={this.state.offices}
                                    placeholder={i18n.translate("default_select")}/>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("default_type")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <Select onChange={this.handleChangeSelect} name="type" value={this.state.typeSelectedModal} 
                                    menuShouldScrollIntoView={false} options={this.state.type} placeholder={i18n.translate("default_select")}/>
                                </div>
                            </div>
                            <br/>
                            <div className="row rowsTypeTask" style={{marginBottom:'20px'}}>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("required_start_photo")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <select class='form-select selectStandard' onChange={this.handleChangeConfig}
                                    id="required_start_photo" value={this.state.required_start_photo}>
                                        {this.state.required_start_photo === "" && 
                                            <option value="">{i18n.translate("default_please_select")}</option>
                                        }  
                                        {this.state.required_start_photo !== "" && 
                                            <BooleanSelect/>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row rowsTypeTask" style={{marginBottom:'20px'}}>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("required_end_photo")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <select class='form-select selectStandard' onChange={this.handleChangeConfig}
                                    id="required_end_photo" value={this.state.required_end_photo}>
                                        {this.state.required_end_photo === "" && 
                                            <option value="">{i18n.translate("default_please_select")}</option>
                                        }  
                                        {this.state.required_end_photo !== "" && 
                                            <BooleanSelect/>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row rowsTypeTask" style={{marginBottom:'20px'}}>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("required_signature")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <select class='form-select selectStandard' onChange={this.handleChangeConfig}
                                    id="required_signature" value={this.state.required_signature}>
                                        {this.state.required_signature === "" && 
                                            <option value="">{i18n.translate("default_please_select")}</option>
                                        }  
                                        {this.state.required_signature !== "" && 
                                            <BooleanSelect/>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row rowsTypeTask" style={{marginBottom:'20px'}}>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("standard_duration")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <input type="number" className="form-control inputStandard" name="standard_duration"
                                    value={this.state.standard_duration} onChange={this.handleChangeForm}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("description")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <textarea className="form-control" value={this.state.description} name="description" onChange={this.handleChangeForm}></textarea>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("responsible")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <Select onChange={this.handleChangeSelect} menuShouldScrollIntoView={false} name="responsible" options={this.state.responsible} 
                                    value={{value: this.state.responsibleValue, label: this.state.responsibleLabel}}/>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                    {i18n.translate("date")} {i18n.translate("time")}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <DateTimePicker className="dateTimeInput" onChange={(date) => this.handleDate(date)} value={new Date(this.state.dateStartToEdit)} />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button className="buttonMzatePrimary" onClick={() => this.updateCreateTask()}>{i18n.translate("save")}</button>
                            <button className="buttonMzateSecondary" onClick={() => this.closeModal()}>{i18n.translate("cancel")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalShowTask} size='lg'>
                        <ModalHeader className='modalHeader'>
                            {i18n.translate("task_title")} 
                            <button className='close' data-dismiss="close" onClick={() => this.closeModalShow()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' style={{paddingTop:"0px"}}>
                            <div className="row" style={{borderBottom:"1px solid darkgray",cursor:"pointer",textAlign:"center",marginBottom: "10px"}}>
                                <div id="abstractOption" className="col-sm-12 col-md-6 col-lg-6 col-xl-6 activeItem" 
                                onClick={() => this.showHideDetailAbstract("abstract")}>{i18n.translate("default_abstract")}</div>
                                <div id="detailOption" className="col-sm-12 col-md-6 col-lg-6 col-xl-6" 
                                onClick={() => this.showHideDetailAbstract("detail")}>{i18n.translate("default_detail")}</div>
                            </div>
                            <div id="abstractDiv">
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("state")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                <>
                                                    {this.state.stateForShow}
                                                </>
                                                }
                                                {this.state.itemShow.itemProps.startDate == null && 
                                                <>
                                                    <Select onChange={this.handleChangeSelect} name="status" menuShouldScrollIntoView={false} options={this.state.status} 
                                                    value={{value: this.state.typeForShow, label: this.state.stateForShow}}/>
                                                </>
                                                }
                                                </>
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("default_client")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                this.state.customerOfficeToShow
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("default_type")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                    <>
                                                        {this.state.typeForShow}
                                                    </>
                                                    }
                                                    {this.state.itemShow.itemProps.startDate == null && 
                                                    <>
                                                        <Select onChange={this.handleChangeSelect} name="type" menuShouldScrollIntoView={false} options={this.state.type} 
                                                        value={{value: this.state.typeForShow, label: this.state.typeForShow}}/>
                                                    </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("required_start_photo")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                <>
                                                    {this.state.required_start_photo === "false" && i18n.translate("false")}
                                                    {this.state.required_start_photo === "true" && i18n.translate("true")}
                                                </>
                                                }
                                                {this.state.itemShow.itemProps.startDate == null && 
                                                <>
                                                    <select class='form-select selectStandard' onChange={this.handleChangeConfig}
                                                    id="required_start_photo" value={this.state.required_start_photo}>
                                                        <BooleanSelect/>
                                                    </select>
                                                </>
                                                }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("required_end_photo")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                        <>
                                                            {this.state.required_end_photo === "false" && i18n.translate("false")}
                                                            {this.state.required_end_photo === "true" && i18n.translate("true")}
                                                        </>
                                                    }
                                                    {this.state.itemShow.itemProps.startDate == null && 
                                                    <>
                                                        <select class='form-select selectStandard' onChange={this.handleChangeConfig} 
                                                        id="required_end_photo" value={this.state.required_end_photo}>
                                                            <BooleanSelect/>
                                                        </select>
                                                    </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("required_signature")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                        <>
                                                            {this.state.required_signature === "false" && i18n.translate("false")}
                                                            {this.state.required_signature === "true" && i18n.translate("true")}
                                                        </>
                                                    }
                                                    {this.state.itemShow.itemProps.startDate == null && 
                                                    <>
                                                        <select class='form-select selectStandard' onChange={this.handleChangeConfig}
                                                        id="required_signature" value={this.state.required_signature}>
                                                            <BooleanSelect/>
                                                        </select>
                                                    </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("tasks_to_do")}:</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.taskToDo.map((t,i) =>{
                                                return(
                                                    <div key={i}>
                                                        <label className="bolder">{t.type}: </label>
                                                        {(t.value.started === "false" || t.value.started === false) && i18n.translate("pending")} 
                                                        {(t.value.started === "true" || t.value.started === true) && (t.value.finished === "false" || t.value.finished === false) && i18n.translate("inProcess")}
                                                        {(t.value.started === "true" || t.value.started === true) && (t.value.finished === "true" || t.value.finished === true) && i18n.translate("finalized")}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("description")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                        this.state.description
                                                    }
                                                    {this.state.itemShow.itemProps.startDate == null && 
                                                    <>
                                                        <textarea className="form-control" value={this.state.description} name="description" onChange={this.handleChangeForm}></textarea>
                                                    </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("responsible")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                        this.state.responsibleLabel
                                                    }
                                                    {this.state.itemShow.itemProps.startDate == null && 
                                                    <>
                                                        <Select onChange={this.handleChangeSelect} menuShouldScrollIntoView={false} name="responsible" options={this.state.responsible} 
                                                        value={{value: this.state.responsibleValue, label: this.state.responsibleLabel}}/>
                                                    </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">{i18n.translate("date")} {i18n.translate("time")}</div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow && 
                                                <>
                                                    {this.state.itemShow.itemProps.startDate != null && 
                                                        this.state.dateToShow
                                                    }
                                                    {this.state.itemShow.itemProps.startDate == null && 
                                                        <DateTimePicker className="dateTimeInput" onChange={(date) => this.handleDate(date)} value={new Date(this.state.dateStartToEdit)} />
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                            {i18n.translate("estimated_duration")} 
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.itemShow != null && <>
                                                {this.state.itemShow.itemProps.startDate != null && 
                                                    <>
                                                        {this.state.estimatedDuration}
                                                    </>
                                                }
                                                {this.state.itemShow.itemProps.startDate == null && 
                                                    <input type="number" className="form-control inputStandard" name="standard_duration"
                                                    value={this.state.standard_duration} onChange={this.handleChangeForm}/>
                                                }
                                            </>} 
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                            {i18n.translate("start_date")} 
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.dateStartResume} 
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                            {i18n.translate("end_date")} 
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.dateEndResume} 
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'10px'}}>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 bolder">
                                            {i18n.translate("default_duration")} 
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            {this.state.durationResume} 
                                        </div>
                                    </div>
                            </div>
                            <div id="detailDiv" style={{display:'none'}}>
                                <div id="containerDetail">
                                    {this.state.taskDetail.length == 0 && <>
                                        <div className={"divNoResultsMzate-"+this.state.instanceType}>
                                            {i18n.translate("no_tasks_done")} 
                                        </div>
                                    </>}  
                                    {this.state.taskDetail
                                    && this.state.taskDetail.map((td) => {
                                        return(
                                        <>
                                            <div id={td.id} className="taskDetailToShow" onClick={() => this.showDetail(td.id)}>
                                                {i18n.translate("default_start")}: {td.startDate} {i18n.translate("default_end")}: {td.endDate} 
                                                <FaIcons.FaCaretRight id={"arrowRigth"+td.id} style={{float:"right"}}/>
                                                <FaIcons.FaCaretDown id={"arrowDown"+td.id} style={{float:"right",display:"none"}}/>
                                            </div>
                                            <div id={"detail"+td.id} className="detailTask" style={{display:"none"}}>
                                            
                                                <Row style={{textAlign:'center'}}>
                                                    <label>{i18n.translate("tasks_title")}</label>
                                                        {JSON.parse(td.tasks).map((t) => {
                                                            return(
                                                                <Col>
                                                                    {t.type} 
                                                                    {t.value.started == 'false' && t.value.finished == 'false' && <>
                                                                        <ProgressBar variant="taskStatusPending" now={100}  label={i18n.translate("pending")} />
                                                                    </>}
                                                                    {t.value.started == 'true' && t.value.finished == 'false' && <>
                                                                        <ProgressBar variant="taskStatusInprocess" now={50} label={i18n.translate("inProcess")} />
                                                                    </>}
                                                                    {t.value.started == 'true' && t.value.finished == 'true' && <>
                                                                        <ProgressBar variant="taskStatusFinished" now={100} label={i18n.translate("finalized")}/>
                                                                    </>}
                                                                </Col>
                                                            )
                                                        })}
                                                </Row>
                                                        
                                                <Row>
                                                    {td.photos.map((p)=>{
                                                        return(
                                                        <>
                                                            {p.type === "SP" &&
                                                                <ImgReportTask message={"start_photo"} multimediaFile={p.multimediaFile} 
                                                                colStyle={divImageLeft}  labelStyle={labelImage} />
                                                            }
                                                            {p.type === "EP" &&
                                                                <ImgReportTask message={"end_photo"} multimediaFile={p.multimediaFile}
                                                                colStyle={divImageRiht}  labelStyle={labelImage}/>
                                                            }
                                                            {p.type === "CS" &&
                                                                <ImgReportTask message={"customer_signature"} multimediaFile={p.multimediaFile}
                                                                colStyle={divImageLeft}  labelStyle={labelImage}/>
                                                            }
                                                        </>) 
                                                    })} 
                                                </Row>

                                                <Row>
                                                    {td.observation && <>
                                                        <Col style={{paddingTop:"50px",paddingLeft:'15px',display:"inline-block"}}>
                                                            <label style={textBlock}>{i18n.translate("observation")}: {td.observation}</label>
                                                        </Col>
                                                    </>}
                                                    {td.explanationSignature && <>
                                                        <Col style={divImageRihtFirm}>
                                                            <label style={labelImage}>{i18n.translate("clarification")}</label>
                                                            <label>{td.explanationSignature}</label>
                                                        </Col>
                                                    </>} 
                                                </Row>
                                            </div>
                                        </>
                                        )
                                    })
                                    } 
                                    
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.itemShow && 
                                <>
                                    {this.state.itemShow.itemProps.startDate == null && 
                                        <>
                                            <button className="buttonMzatePrimary" onClick={() => this.updateCreateTask()}>{i18n.translate("update")}</button>
                                            <button className="buttonMzateSecondary" onClick={() => this.deleteTask()}>{i18n.translate("delete")}</button>
                                            <button className="buttonMzateSecondary" onClick={() => this.generateReport()}>{i18n.translate("generate_report")}</button>
                                        </>
                                    }
                                    {this.state.itemShow.itemProps.startDate != null && 
                                        <>
                                            <button className="buttonMzatePrimary" onClick={() => this.generateReport()}>{i18n.translate("generate_report")}</button>
                                        </>
                                    }
                                </>
                            }
                            <button className="buttonMzateSecondary" onClick={() => this.closeModalShow()}>{i18n.translate("default_close")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalConflict}>
                        <ModalHeader className='modalHeader'>
                            {this.state.modalConflictTitle}
                            <button className='close' data-dismiss="close" onClick={() => this.closeModalErrorConflict()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' >
                            <ul>
                                {this.state.errorsList.map((error) => {
                                    return(
                                        <li>{error}</li>
                                    )
                                })}
                                {this.state.conflictsList.length > 0 && 
                                    <>
                                        {i18n.translate("task_conflict_message")}
                                        {this.state.conflictsList.map((conflict) => {
                                            return(
                                                <>
                                                    <li><span className="bolder">{i18n.translate("task_title")}: </span>{conflict.description}</li>
                                                    <li style={{listStyleType: "none"}}><span className="bolder">{i18n.translate("start_date")}: </span>{conflict.plannedStartDate}</li>
                                                    <li style={{listStyleType: "none"}}><span className="bolder">{i18n.translate("end_date")}: </span>{conflict.plannedEndDate}</li>
                                                </>
                                            )
                                        })}
                                    </>
                                }
                            </ul>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.modalConflictTitle === i18n.translate("default_errors") && 
                                <button className="buttonMzatePrimary" onClick={() => this.closeModalErrorConflict()}>{i18n.translate("default_close")}</button>
                            }
                            {this.state.modalConflictTitle === i18n.translate("default_conflicts") && 
                            <>
                                {this.state.isSave && 
                                    <button className="buttonMzatePrimary" onClick={() => this.executeCreate()}>{i18n.translate("default_accept")}</button>
                                } 
                                {!this.state.isSave && 
                                    <button className="buttonMzatePrimary" onClick={() => this.executeUpdate()}>{i18n.translate("default_accept")}</button>
                                }
                                <button className="buttonMzateSecondary" onClick={() => this.closeModalErrorConflict()}>{i18n.translate("cancel")}</button>
                            </>
                            }
                            
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalConfirmDelete}>
                        <ModalHeader className='modalHeader'>
                            {i18n.translate("default_confirm")}
                            <button className='close' data-dismiss="close" onClick={() => this.closeModalConfirmDelete()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' >
                            {i18n.translate("delete_confirm_task")}
                        </ModalBody>
                        <ModalFooter>
                            <button className="buttonMzatePrimary" onClick={() => this.executeDeleteTask()}>{i18n.translate("default_confirm")}</button>
                            <button className="buttonMzateSecondary" onClick={() => this.closeModalConfirmDelete()}>{i18n.translate("cancel")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalConfirmDrag}>
                        <ModalHeader className='modalHeader'>
                            {i18n.translate("default_confirm")}
                            <button className='close' data-dismiss="close" onClick={() => this.closeModalConfirm()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' >
                            {i18n.translate("confirm_passing_the_task")}
                            {this.state.model !== "" && 
                            <>
                                <Row>
                                    <Col sm={12} md={12} lg={12} xl={12} style={{textAlign:'center'}}> 
                                        <div className="bolder">{this.state.model.description}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} lg={6} xl={6}>
                                        <span className="bolder">
                                            {i18n.translate("current_responsible")}: 
                                        </span>{this.state.itemShow && <>
                                            {this.state.itemShow.itemProps.responsibleFullname}
                                        </>}
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={6}>
                                        <span className="bolder">{i18n.translate("new_responsible")}: </span>{this.state.model.responsibleUsername}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} lg={6} xl={6}>
                                        <span className="bolder">{i18n.translate("current_date")}: </span>{this.state.dateDrag}
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={6}>
                                        <span className="bolder">{i18n.translate("new_date")}: </span>{this.state.model.plannedDate}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} lg={6} xl={6}>
                                        <span className="bolder">{i18n.translate("current_time")}: </span>{this.state.timeDrag}
                                    </Col>
                                    <Col sm={12} md={6} lg={6} xl={6}>
                                        <span className="bolder">{i18n.translate("new_time")}: </span>{this.state.model.plannedHour}
                                    </Col>
                                </Row>
                            </>
                            }
                            
                        </ModalBody>
                        <ModalFooter>
                            <button className="buttonMzatePrimary" onClick={() => this.updateCreateTask()}>{i18n.translate("default_confirm")}</button>
                            <button className="buttonMzateSecondary" onClick={() => this.closeModalConfirm()}>{i18n.translate("cancel")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalReport}>
                        <ModalHeader className='modalHeader'>
                            {i18n.translate("generate_report")}
                            <button className='close' data-dismiss="close" onClick={() => this.closeModalReport()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' >
                        <div className='form-group row'>
                            <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <b>{i18n.translate("select_type_report")}: </b>
                            </div>
                            <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <select className="form-select selectStandard">
                                    <option value="tasks.reports">{i18n.translate("task_reports")}</option>
                                </select>
                            </div>
                            {' '}
                            <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <b>{i18n.translate("default_type")}: </b>
                            </div>
                            <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <SelectGIE modal="true" modalReport={true}  allUsers="true"/>
                            </div>
                            {' '}
                            <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4' style={{marginTop:'15px'}}>
                                <b>{i18n.translate("date")}: </b>
                            </div>
                            <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8' style={{marginTop:'15px'}}>
                                <DateRangePicker
                                    initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate }}
                                    onCallback={this.handleCallback}
                                >
                                    <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard" />
                                </DateRangePicker>
                            </div>
                        </div>
                        </ModalBody>
                        <ModalFooter>
                            <button className="buttonMzatePrimary" onClick={() => this.executeGenerateReport()}>{i18n.translate("generate_report")}</button>
                            <button className="buttonMzateSecondary" onClick={() => this.closeModalReport()}>{i18n.translate("cancel")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.defaulValueConfigsModal}>
                        <ModalHeader className='modalHeader'>
                            {i18n.translate("default_confirm")}
                            <button className='close' data-dismiss="close" onClick={() => this.closeModalReport()}>x</button>
                        </ModalHeader>
                        <ModalBody className='modalBody' >
                            {i18n.translate("confirm_default_values_type_tasks")} 
                        </ModalBody>
                        <ModalFooter>
                            <button className="buttonMzatePrimary" onClick={() => this.changeConfigValue(this.state.optionDefaulValueConfigs)}>{i18n.translate("default_confirm")}</button>
                            <button className="buttonMzateSecondary" onClick={() => this.closeModalReport()}>{i18n.translate("cancel")}</button>
                        </ModalFooter>
                    </Modal>
                    <div>
                        <div id="containerReport" style={container}>
                            <Row style={{height:'101px'}}>
                                <Col style={{paddingTop:'30px'}} sm={12} md={4} lg={4} xl={4}>
                                    {this.state.instanceType === this.state.independent && 
                                        <img src={logo} />
                                    }
                                    {this.state.instanceType === this.state.castMzate && 
                                        <img src={logoCast} />
                                    }
                                </Col>
                                <Col sm={12} md={4} lg={4} xl={4} style={{paddingTop:'45px',textAlign:'center'}}>
                                    <label style={{fontSize:'15px',fontWeight:'bolder'}}>
                                        {i18n.translate("task_report")}
                                    </label>
                                </Col>
                                <Col sm={12} md={4} lg={4} xl={4} style={{paddingTop:'40px',textAlign: 'end'}}>
                                    <label>
                                        <span className="bolder">{i18n.translate("default_user")}: </span> 
                                        {this.state.userReport}
                                    </label> 
                                    <label>
                                        <span className="bolder">{i18n.translate("date")}: </span> 
                                        {this.state.dateReport}
                                    </label>
                                </Col>
                            </Row>
                            <hr></hr>
                            {this.state.itemShow && 
                                <>
                                    <div>
                                        <label><span style={bolder}>{i18n.translate("state")} </span> {this.state.itemShow.itemProps.status} <div className="taskStatus" style={{background: this.state.itemShow.itemProps.style.background}}></div></label>
                                        <label style={labelRight}><span style={bolder}>{i18n.translate("default_client")} </span> {this.state.itemShow.itemProps.customerName}</label>
                                    </div>
                                    <div>
                                        <label><span style={bolder}>{i18n.translate("default_type")}</span> {this.state.typeForShow}</label>
                                        <label style={labelRight}><span style={bolder}>{i18n.translate("default_duration")}</span> {this.state.standard_duration} min</label>
                                    </div>
                                    <div>
                                        <label>
                                        <span style={bolder}>{i18n.translate("description")} </span> 
                                        {this.state.itemShow.title}
                                        </label>
                                    </div>
                                    <div>
                                        <label style={labelRight}>
                                            <span style={bolder}>{i18n.translate("responsible")} </span> 
                                            {this.state.itemShow.itemProps.responsibleUsername}
                                        </label>
                                        <label style={textBlock}>
                                            <span style={bolder}>{i18n.translate("date")} {i18n.translate("time")} </span> 
                                            {this.state.itemShow.itemProps.plannedDateStr}
                                        </label>
                                    </div>
                                </>
                            }
                            <hr></hr>
                            {this.state.taskDetail && 
                                this.state.taskDetail.map((td, i) => {
                                    return(
                                        <div style={i === 0 ? divRoot : divRoot2}>
                                            <div style={textCenter}>
                                                <label>
                                                    <span style={bolder}>{i18n.translate("default_start")} </span>  
                                                    {td.startDate} 
                                                    <span style={bolder}> {i18n.translate("default_end")} </span> 
                                                    {td.endDate}
                                                </label>
                                            </div>
                                            <Row style={{textAlign:'center'}}>
                                                <label>{i18n.translate("tasks_title")}</label>
                                                {JSON.parse(td.tasks).map((t) => {
                                                    return(
                                                        <Col>
                                                            {t.type} 
                                                            {t.value.started == 'false' && t.value.finished == 'false' && <>
                                                            <ProgressBar variant="taskStatusPending" now={100}  label={i18n.translate("pending")} />
                                                            </>}
                                                            {t.value.started == 'true' && t.value.finished == 'false' && <>
                                                            <ProgressBar variant="taskStatusInprocess" now={50} label={i18n.translate("inProcess")} />
                                                            </>}
                                                            {t.value.started == 'true' && t.value.finished == 'true' && <>
                                                            <ProgressBar variant="taskStatusFinished" now={100} label={i18n.translate("finalized")}/>
                                                            </>}
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                            <Row>
                                                {td.photos.map((p) =>{
                                                    return(
                                                        <>
                                                            {p.type == "SP" &&
                                                                    <Col style={divImageLeft}>
                                                                    <label style={labelImage}>{i18n.translate("start_photo")}</label>
                                                                    <img src={"data:image/png;base64,"+p.multimediaFile} className="img-fluid img-thumbnail taskDetailPhoto"
                                                                    width="200px" height="auto"/>
                                                                    </Col> 
                                                            }
                                                            {p.type == "EP" &&
                                                                    <Col style={divImageRiht}>
                                                                    <label style={labelImage}>{i18n.translate("end_photo")}</label>
                                                                    <img src={"data:image/png;base64,"+p.multimediaFile} className="img-fluid img-thumbnail taskDetailPhoto"
                                                                    width="200px" height="auto"/>
                                                                    </Col> 
                                                            }
                                                            {p.type == "CS" &&
                                                                    <Col style={divImageLeft}>
                                                                    <label style={labelImage}>{i18n.translate("customer_signature")}</label>
                                                                    <img src={"data:image/png;base64,"+p.multimediaFile} className="img-fluid img-thumbnail taskDetailPhoto"
                                                                    width="200px" height="auto"/>
                                                                    </Col> 
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </Row>
                                            <Row>
                                                {td.observation && <>
                                                    <Col style={{paddingTop:"50px",paddingLeft:'15px',display:"inline-block"}}>
                                                    <label style={textBlock}>{i18n.translate("observation")}: {td.observation}</label>
                                                    </Col>
                                                </>}
                                                {td.explanationSignature && <>
                                                    <Col style={divImageRihtFirm}>
                                                    <label style={labelImage}>{i18n.translate("clarification")}</label>
                                                    <label>{td.explanationSignature}</label>
                                                    </Col>
                                                </>} 
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            </>
        )
    } 

}

export default TaskIndex;