import '../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import spinner from "../img/independent/spinner_movilizate.gif";
import spinnerCast from "../img/cast_mzate/spinner_movilizate.gif";

const DefaultSpinner = ({instanceType,independent}) => {
    const [spinnerToUse, setSpinner] = useState("");
    useEffect(()=>{
        if(instanceType == independent){
            setSpinner(spinner);
        }else{
            setSpinner(spinnerCast);
        }
	}, [])
    return(
        <div className='modal fade show' style={{display:'block',background:'rgba(0,0,0,0.4)'}}>
            <img src={spinnerToUse} className='modal-dialog modal-lg' style={{left: '40%'}}/>
        </div>
    )
};

export default DefaultSpinner;