import React from "react";
import * as FaIcons from "react-icons/fa";
import administration from "../img/iconos-roll-over/organizacion.png";
import users from "../img/iconos-roll-over/usuarios.png";
import establishments from "../img/iconos-roll-over/establecimiento.png";
import permissions from "../img/iconos-roll-over/permisos.png";
import configuration from "../img/iconos-roll-over/configuracion.png";
import security from "../img/iconos-roll-over/seguridad.png";
import audit from "../img/iconos-roll-over/auditoria_de_configuraciones.png";
import notifications from "../img/iconos-verdes/reportes_automaticos-verde.png";
import operations from "../img/iconos-roll-over/operaciones_administrativas.png";
import categories from "../img/iconos-roll-over/categorias.png";

export const SidebarDataAdministration = [
    {
        title: "administration_title",
        path: "/Administration/",
        icon: <img src={administration} width="32px"/>,
        id: 'administrationAdministration'
    },
    {
        title: "default_users",
        path: "/Administration/",
        icon: <img src={users} width="32px"/>,
        id: 'administrationUsers'
    },
    {
        title: "default_stablishments",
        path: "/Administration/",
        icon: <img src={establishments} width="32px"/>,
        id: 'administrationStablishments'
    },
    {
        title: "default_permissions",
        path: "/Administration/",
        icon: <img src={permissions} width="32px"/>,
        id: 'administrationPermissions'
    },
    {
        title: "settings_title",
        path: "/Administration/",
        icon: <img src={configuration} width="32px"/>,
        id: 'administrationConfiguration'
    },
    {
        title: "default_security",
        path: "/Administration/",
        icon: <img src={security} width="32px"/>,
        id: 'administrationSecurity'
    },
    {
        title: "audit_title",
        path: "/Administration/",
        icon: <img src={audit} width="32px"/>,
        iconClosed: <FaIcons.FaChevronDown className="arrowIcon"/>,
        iconOpened: <FaIcons.FaChevronDown className="arrowIcon"/>,
        id: 'administrationAudit',
        subNav: [
            {
                title: "settings_title",
                path: "/Administration/",
                icon: <FaIcons.FaCircle className="iconSubmenu"/>,
                id: "administrationConfigurations"
            },
            {
                title: "web_access",
                path: "/Administration/",
                icon: <FaIcons.FaCircle className="iconSubmenu"/>,
                id: "administrationWebAccess"
            },
            {
                title: "blocking",
                path: "/Administration/",
                icon: <FaIcons.FaCircle className="iconSubmenu"/>,
                id: "administrationBlocking"
            } 
        ]  
    },
    {
        title: "notifications",
        path: "/Administration/",
        icon: <img src={notifications} width="32px"/>,
        iconClosed: <FaIcons.FaChevronDown className="arrowIcon"/>,
        iconOpened: <FaIcons.FaChevronDown className="arrowIcon"/>,
        id: "administrationNotification",
        subNav: [
            {
                title: "reports",
                path: "/Administration/",
                icon: <FaIcons.FaCircle className="iconSubmenu"/>,
                id: "administrationReports" 
            },
            {
                title: "events",
                path: "/Administration/events",
                icon: <FaIcons.FaCircle className="iconSubmenu"/>,
                id: "administrationEvents" 
            }  
        ] 
    },
    {
        title: "operations",
        path: "/Administration/",
        icon: <img src={operations} width="32px"/>,
        id: 'administrationOperations'
    },
    {
        title: "integration",
        path: "/Administration/",
        icon: <img src={categories} width="32px"/>,
        id: 'administrationIntegration'
    }
] 