import axios from "axios";
export const url = process.env.REACT_APP_API_URL;

export const saveEditStorage = (newPlan) => {
    let result;
    if(newPlan.qtyFrom == "" || newPlan.qtyFrom == null || 
    newPlan.storageSize == "" || newPlan.storageSize == null){
        result = 1
    }else{
        result = 0;
    }    
    
    return result
}

export async function getPlans() {
    try {
      const plans = await axios.get(url+"defaultStorageRange/defaultStorageRangeList");
      return plans.data;
    } catch (err) {
      return new Map();
    }
}

export const saveEditExtraStorage = (newPlan) => {
  let result;
  if(newPlan.storageSize == "" || newPlan.storageSize == null || 
  newPlan.price == "" || newPlan.price == null || 
  newPlan.moduleCode == "" || newPlan.moduleCode == null || 
  newPlan.moduleDescription == "" || newPlan.moduleDescription == null || 
  newPlan.stripePricesId == "" || newPlan.stripePricesId == null){
      result = 1
  }else{
      result = 0;
  }    
  
  return result
} 

export async function getExtraPlans() {
  try {
    const plans = await axios.get(url+"extraStoragePack/extraStoragePacks?pageNumber=0&limit=50");
    return plans.data;
  } catch (err) {
    return new Map();
  }
}