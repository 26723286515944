
export const showSidebarCollapseFunction = () => {
    document.querySelector(".mzateLogo").classList.remove("displayNone");
    document.querySelector(".mzateCollapseLogo").classList.add("displayNone");
    let span = document.querySelectorAll(".sidebar2 a div span");
    for(var i = 0; i < span.length; i++){
        span[i].classList.remove("displayNone");
    } 
    document.querySelector(".sidebar2").classList.remove("sidebar2Collapse");
    document.querySelector(".align-items-center").classList.remove("containerNoCollapse");
} 

export const hideSidebarCollapseFunction = () => {
    document.querySelector(".mzateLogo").classList.add("displayNone");
    document.querySelector(".mzateCollapseLogo").classList.remove("displayNone");
    let span = document.querySelectorAll(".sidebar2 a div span");
    for(var i = 0; i < span.length; i++){
        span[i].classList.add("displayNone");
    }
    document.querySelector(".sidebar2").classList.add("sidebar2Collapse");
    document.querySelector(".align-items-center").classList.add("containerNoCollapse");
    document.querySelector("#nav-top").classList.add("nav-topCollase");
} 

export const stopPropagation =e=>{
    e.stopPropagation();
}

export const getDateForShow = (date) => {
    var date = new Date(date);
    var m = date.getMonth() + 1;
    var mes = (m < 10) ? '0' + m : m;
    var day = date.getDate();
    var dayForShow = (day < 10) ? '0' + day : day;
    date = dayForShow+"-"+mes+"-"+date.getFullYear();
    return date
}