import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarMzateAdministration from "../components/SidebarMzateAdministration";
import { useLocation } from 'react-router-dom';
import AbmAudit from "./adminContent/AbmAudit";
import { useEffect, useState } from "react";
import AbmStorage from "./adminContent/AbmStorage";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions"; 
import AppInstallAudit from "./adminContent/AppInstallAudit";

const AdministrationMzate = () =>{
    const [heightContent, setHeightContent] = useState("");
    let content = "";
    let url = useLocation().pathname;
    if(url === "/AdministrationMzate/audit"){
        url = "/integrationApiAudit.html";
        content = <iframe width="100%" height={heightContent} sandbox="allow-scripts allow-same-origin" src={url}></iframe>;
    }else if(url === "/AdministrationMzate/videos" || url === "/AdministrationMzate" || url === "/" ){
        url = "/videos.html";
        content = <iframe width="100%" id="videoIframe" height={heightContent} sandbox="allow-scripts allow-same-origin" src={url}></iframe>;
    }else if(url === "/AdministrationMzate/configurations"){
       content = <AbmAudit/>;
    }else if(url === "/AdministrationMzate/storage"){
        content = <AbmStorage/>;
    }else if(url === "/AdministrationMzate/appInstallAudit"){
        content = <AppInstallAudit/>;
    }  
    
    if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
        window.history.replaceState(null,document.title,window.location.origin + "/" + '?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
    }

    let flag = 0;
	const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag === 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }
    let altura = window.innerHeight - 70;
    useEffect(()=>{
		setHeightContent(altura);
	}, [])
    
    return (
        <div className="tab-pane fade" id="nav-controlAdministration" role="tabpanel" aria-labelledby="nav-controlAdministration-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarMzateAdministration/>
            </div>
            
            <div className="content" id="contentAdministrationMzate">
                {content}
            </div>
        </div>
    );
    
};
export default AdministrationMzate;
