export const getArrayOfMapValues = () => {
    var values = []; 
    var taskToDo = []; 
    var divTaskType = document.querySelectorAll(".divTaskType");
    var divTaskToDo;
    for(var i = 0; i < divTaskType.length; i++){
        taskToDo = [];
        divTaskToDo = document.querySelectorAll(".divTaskType-"+i+" .arrayStringValue input");
        for(var j = 0; j < divTaskToDo.length; j++){
            taskToDo.push(divTaskToDo[j].value);
        } 
        values.push({"type":document.querySelector(".divTaskType-"+i+" .configType").value,"settings":
    {"required_end_photo":{"value":document.querySelector(".divTaskType-"+i+" .required_end_photo").value,"type":"BOOLEAN"},
    "required_signature":{"value":document.querySelector(".divTaskType-"+i+" .required_signature").value,"type":"BOOLEAN"},
    "required_start_photo":{"value":document.querySelector(".divTaskType-"+i+" .required_start_photo").value,"type":"BOOLEAN"},
    "standard_duration":{"value":document.querySelector(".divTaskType-"+i+" .standard_duration").value,"type":"numeric"},
    "tasks_to_do":{"value":taskToDo,"type":"ARRAY_STRING"}}})
    }
    return values;
}