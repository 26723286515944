import '../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import parse from 'html-react-parser';
import { DefaultAjax, modalForbidden } from "../components/DefaultAjax";

class DefaultPortfolio extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            portfolioHTML: "",
            instanceId: props.instanceId,
            instanceName: props.instanceName
        }
    } 

    componentDidMount = () => {
        this.getPortFolio();
    } 

    getPortFolio = () => {
        DefaultAjax('').get("adminNotifications/getPortafolio?instanceId="+this.state.instanceId+"&instanceName="+this.state.instanceName+"&isShow=false,errorInPortafolio=")
        .then((res) => {
            if(res){
                this.setState({portfolioHTML:parse(res.data)})
            } 
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    render(){
        return (
            this.state.portfolioHTML
        )
    } 
} 

export default DefaultPortfolio;