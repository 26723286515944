import React from "react";
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import Timeline,  {
    TimelineHeaders,
    SidebarHeader,
    DateHeader
  } from "react-calendar-timeline";
import 'react-calendar-timeline/lib/Timeline.css';
import i18n from "i18n-react";
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import { setBackgroundToTask} from "./getTaskFunctions";

const url = process.env.REACT_APP_API_URL;

class TimelineCalendar extends React.Component {
    constructor(props) {
        super(props);
        let statusSetting = [0,1,2,3,4,5];
        this.state={
            service: props.service,
            groups: props.groups,
            items: props.items,
            itemShow:props.items[0],
            offices: props.offices,
            officeCode: "",
            modalTask:false,
            modalShowTask:false,
            modalConflict: false,
            modalConfirmDrag: false,
            modalConfirmDelete: false,
            statusSetting:statusSetting,
            background:"",
            text:"",
            responsible: props.responsible, 
            type: props.type,
            typeToChange: props.typeToChange,
            status: [],
            startDate: props.startDate,
            endDate: props.endDate,
            typeForShow:"",
            stateForShow:"",
            responsibleValue:"",
            responsibleLabel:"",
            dateStartToEdit:"",
            taskDetail: [],
            required_start_photo: "",
            required_end_photo: "",
            required_signature: "",
            standard_duration: "",
            description:'',
            taskToDo: [],
            errorsList: [],
            conflictsList: [],
            modalConflictTitle: "",
            model: "",
            dateToShow: "",
            isSave: false,
            instanceType:"",
            instanceTypeDescription:"",
            independent:"",
            castMzate:"",
            dateRangeCalendar:[],
            startDateToCompare:new Date(),
            endDateToCompare:new Date(),
            selected: [],
            stage: props.stageForFilter,
            filterSubStatusDelayedStart: props.filterSubStatusDelayedStart,
            filterSubStatusEnding: props.filterSubStatusEnding
        };
        this.updateCreateTask = props.onClick;
        this.handleDate = props.handleDate;
        this.handleChangeSelect = props.handleChangeSelect;
        this.newTask = props.newTask;
        this.showTask = props.showTask;
        this.dragTask = props.dragTask;
        this.handleCallback = props.handleCallback;
        this.getItemsAfterScroll = props.getItemsAfterScroll;
        this.updateStageValue = props.updateStageValue;
    }

    onItemMove = (taskId, dragTime, userId) => {
        this.dragTask(taskId, dragTime, userId)
    } 

    onItemResize = (itemId, time, edge) => {
        
    } 

    onCanvasClick = (userId, time, e) => {
        this.newTask(userId, time, e);
    }

    onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas, unit) => {
        updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
        if(this.dateRangeForSearhCalendar(this.state.startDateToCompare) != this.dateRangeForSearhCalendar(moment(new Date(visibleTimeStart))) 
        && this.dateRangeForSearhCalendar(this.state.endDateToCompare) != this.dateRangeForSearhCalendar(moment(new Date(visibleTimeEnd)))){
            this.handleDateChange(moment(new Date(visibleTimeStart)),moment(new Date(visibleTimeEnd)))
            this.setState({
                startDateToCompare:moment(new Date(visibleTimeStart)),
                endDateToCompare:moment(new Date(visibleTimeEnd))
            })
        }
    } 

    dateRangeForSearhCalendar = (date) => {
        let startDate = new Date(date);
        let m = startDate.getMonth() + 1;
        let mes = (m < 10) ? '0' + m : m;
        let day = startDate.getDate();
        let dayForShow = (day < 10) ? '0' + day : day;
        startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
        return startDate;
    }

    handleDateChange = (start, end, label) => {
        this.handleCallback(start, end, label);
        let dateRange = []; 
        document.querySelector("#dateInInputStart").value = this.dateRangeForSearhCalendar(start._d);
        document.querySelector("#dateInInputEnd").value = this.dateRangeForSearhCalendar(end._d);
        dateRange.push(this.dateRangeForSearhCalendar(start._d));
        dateRange.push(this.dateRangeForSearhCalendar(end._d));
        this.updateTaskCalendar(dateRange[0],dateRange[1]);
    } 

    filterByStageLegends = (stage,e) => {
        let arrayStage = this.state.stage;
        let index = arrayStage.indexOf(stage);
        if(arrayStage.indexOf(stage) === -1){
            e.target.classList.add("divTaskStatusInactive");
            arrayStage.push(stage);
            if(stage == 1){ 
                document.querySelectorAll(".divTaskStatus")[3].style.pointerEvents = "none";
                if(arrayStage.indexOf(4) === -1){
                    arrayStage.push(4);
                    document.querySelectorAll(".divTaskStatus")[3].classList.add("divTaskStatusInactive");
                    this.setState({filterSubStatusDelayedStart:4});
                }   
            }else if(stage == 2){
                document.querySelectorAll(".divTaskStatus")[4].style.pointerEvents = "none";
                if(arrayStage.indexOf(3) === -1){
                    arrayStage.push(3);
                    document.querySelectorAll(".divTaskStatus")[4].classList.add("divTaskStatusInactive"); 
                    this.setState({filterSubStatusEnding:3});
                } 
            }  
        }else{
            e.target.classList.remove("divTaskStatusInactive");
            arrayStage.splice(index, 1); 
            if(stage == 1){
                if(arrayStage.indexOf(4) != -1){
                    document.querySelectorAll(".divTaskStatus")[3].style.pointerEvents = "";
                    if(this.state.filterSubStatusDelayedStart == 4){
                        document.querySelectorAll(".divTaskStatus")[3].classList.remove("divTaskStatusInactive");
                        index = arrayStage.indexOf(1);
                        arrayStage.splice(index, 1);
                        this.setState({filterSubStatusDelayedStart:0});
                    }     
                } 
            }else if(stage == 2){
                if(arrayStage.indexOf(3) != -1){
                    document.querySelectorAll(".divTaskStatus")[4].style.pointerEvents = "";
                    if(this.state.filterSubStatusEnding == 3){
                        document.querySelectorAll(".divTaskStatus")[4].classList.remove("divTaskStatusInactive"); 
                        index = arrayStage.indexOf(2);
                        arrayStage.splice(index, 1);
                        this.setState({filterSubStatusEnding:0});
                    } 
                } 
            } 
        } 
        this.setState({stage:arrayStage});
        this.updateStageValue(arrayStage, this.state.filterSubStatusDelayedStart,this.state.filterSubStatusEnding);
        this.updateTaskCalendar(document.querySelector("#dateInInputStart").value, document.querySelector("#dateInInputEnd").value, arrayStage);
    } 

    updateTaskCalendar = (startDate, endDate,stage) => {
        if(stage == undefined){
            stage = this.state.stage;
        } 
        let items = []; 
        let flagResponsible = false;

        if(stage.length == 6){
            this.setState({items:setBackgroundToTask(items)});
        }else{
            let resposiblesSplit = []; 
            if(document.querySelector("#inputResponsibleSelected").value != ''){
                resposiblesSplit = document.querySelector("#inputResponsibleSelected").value.split(",");
            } 

            let params = {

            };
            params.max = "15";
            params.pageNumber = "0";
            params.responsibles = JSON.stringify(resposiblesSplit);
            params.taskToDo = "";
            params.status = document.querySelector("#inputStateSelected").value;
            params.type = document.querySelector("#inputTypeSelected").value;
            params.officeFilter = "";
            params.startDate = startDate;
            params.endDate = endDate;
            params.stage = "[]";
            if(stage){
                params.stage = JSON.stringify(stage);
            } 
            
            DefaultAjax('').get("task/tasks?paramsJson="+encodeURIComponent(JSON.stringify(params)))
            .then((res) => { 
                if(res){
                    let result = JSON.parse(res.data);
                    for(let i = 0; i < result.tasksList.length; i++){
                        if(result.tasksList[i].responsibleUsername === "" || result.tasksList[i].responsibleUsername === undefined){
                            result.tasksList[i].responsibleUsername = null;
                        } 
                        items.push({"id":result.tasksList[i].id,"group":result.tasksList[i].responsibleUsername,
                        "title":result.tasksList[i].description,"start_time":moment(result.tasksList[i].plannedDateStart),
                        "end_time":moment(result.tasksList[i].plannedDateEnd),"canMove":true,"canResize":false,"canChangeGroup":true,
                        "itemProps":result.tasksList[i]
                        });
                        items[i].itemProps.className = 'weekend';
                        items[i].itemProps.ariaHidden = true;
                        items[i].itemProps.style = {"background":'#1B9CD9',"borderRadius":'5px'};
                        if(items[i].itemProps.responsibleFullname == null || items[i].itemProps.responsibleFullname === "" || items[i].itemProps.responsibleFullname === undefined){
                            flagResponsible = true;
                        } 
                    }
                    this.setState({items:setBackgroundToTask(items)});
                    this.getItemsAfterScroll(items);
                    if(items.length === 0){
                        items = this.state.items;
                    }
                } 
            }).catch((error) =>{
                this.setState({
                    modalContent:"",
                    spinner:"",
                    component:error.message
                });
                if (error.response) {
                    if(error.response.status === 403){
                        setTimeout(()=> { 
                            this.setState({modalContent:modalForbidden()});
                        }, 10);
                    } 
                }
            });
        }  

    } 

    componentDidMount() {
        let nav = document.querySelector("#nav-top").offsetHeight;
        let navbar = document.querySelector(".navbar").offsetHeight;
        let altura = window.innerHeight - nav - navbar - 80;
        let bodyContent = document.querySelector(".react-calendar-timeline");
        document.querySelector(".react-calendar-timeline").style.overflow = "auto"
        bodyContent.style.maxHeight = altura + "px";
    }

    handleItemClick = (itemId, e, time) => {
        let array = [];
        if(this.state.selected[0] && this.state.selected[0] == itemId){
            array = [];
        }else{
            array.push(itemId);
            this.showTask(itemId, e, time)
        } 

        this.setState({selected:array})
    } 

    hidePopUp = () => {
        document.querySelectorAll(".popup")[0].style.display = 'none';
        DefaultAjax('').post('/userPreference?key=taskPopUpLegend&value=false&userId='+document.querySelector("#userId").value)
        .then((res) => {
            
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    } 

    render(){
         
        return(
            <>
                
                <Timeline
                groups={this.state.groups}
                items={this.state.items}
                stackItems
                fullUpdate
                defaultTimeStart={this.state.startDate}
                defaultTimeEnd={this.state.endDate}
                selected={this.state.selected}
                onItemClick={this.handleItemClick}
                onItemSelect={this.handleItemClick}
                onItemMove={(itemId, dragTime, newGroupOrder) => this.onItemMove(itemId, dragTime, newGroupOrder)}
                onItemResize={(itemId, time, edge) => this.onItemResize(itemId, time, edge)} 
                onCanvasClick={(groupId, time, e) => this.onCanvasClick(groupId, time, e)}
                onTimeChange = {(visibleTimeStart, visibleTimeEnd, updateScrollCanvas, unit) => this.onTimeChange(visibleTimeStart, visibleTimeEnd, updateScrollCanvas, unit)} 
                >
                    <TimelineHeaders className="sticky">
                        <SidebarHeader>
                            {({ getRootProps }) => {
                            return <div {...getRootProps()}><div style={{color:"white",fontWeight:"bolder",textAlign:"center"}}>{i18n.translate("default_users")}</div></div>;
                            }}
                        </SidebarHeader>
                        <DateHeader unit="primaryHeader" />
                        <DateHeader />
                    </TimelineHeaders>
                </Timeline>
                <div class="popup popupDiv">
                    <div style={{position: 'absolute',right: '10px',top: '5px',cursor: 'pointer'}} 
                    onClick={()=> this.hidePopUp()}>x</div>
                    {i18n.translate("can_filter_on_these_statuses")}
                </div>
                <div style={{display: "inline-flex",paddingTop: "5px",position:"absolute",bottom:"11px"}}>
                    {this.state.statusSetting.map((setting) => {
                        return(
                            <>
                                {setting == 0 && 
                                    <div className="divTaskStatus" id={"divTaskStatus-"+1} onClick={(e)=>this.filterByStageLegends(1,e)} style={{background:"#1B9CD9"}}>{i18n.translate("pending")}</div>
                                }
                                {setting == 1 &&
                                    <div className="divTaskStatus" id={"divTaskStatus-"+2} onClick={(e)=>this.filterByStageLegends(2,e)} style={{background:"#93ccea"}}>{i18n.translate("inProcess")}</div>
                                }
                                {setting == 4 &&
                                    <div className="divTaskStatus" id={"divTaskStatus-"+5} onClick={(e)=>this.filterByStageLegends(5,e)} style={{background:"#8DBE22"}}>{i18n.translate("finalized")}</div>
                                }
                            </>
                        )
                    })}
                    <div style={{display: "inline-flex",marginLeft:"20px",borderLeft:"1px solid",paddingLeft:"16px"}}>
                        <div className="divTaskStatus" id={"divTaskStatus-"+4} onClick={(e)=>this.filterByStageLegends(4,e)} style={{background:"#F08080"}}>{i18n.translate("lateStart")}</div>
                        <div className="divTaskStatus" id={"divTaskStatus-"+3} onClick={(e)=>this.filterByStageLegends(3,e)} style={{background:"#ffe440"}}>{i18n.translate("ending")}</div>
                    </div>
                </div>
            </>
        )
    }
}

export default TimelineCalendar;