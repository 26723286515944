export const getArrayValues = (e) => {
    var input = e.target.nextElementSibling.value;
    var newValues = []; 
    let inputs = document.querySelectorAll(".arrayStringValue input");
    for(var i = 0; i < inputs.length; i++){
        if(input != inputs[i].value){
            newValues.push(inputs[i].value);
        } 
    }
    return newValues;
}

export const setArrayValues = (e,key) => {
    var newValues = []; 
    let inputs = document.querySelectorAll(".arrayStringValue input");
    for(var i = 0; i < inputs.length; i++){
        newValues.push(inputs[i].value);
    } 
    if(key == 'TASK_STATUS'){
        newValues.splice((newValues.length-1),0,"");
    }else{
        newValues.push("");
    } 
    
    return newValues;
}