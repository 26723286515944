import React from "react";
import { SidebarDataTasks } from "./SidebarDataTasks";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import FooterSidebar from "./FooterSidebar";

const SidebarTasks = () => {
	 
	return (
		<>
		<IconContext.Provider value={{ color: "#fff" }}>
			
			<ul className="ulSidebar" id="ulSidebar">
				{SidebarDataTasks.map((item, index) => {
					return <SubMenu item={item} key={index} />;
				})}
			</ul>
			<FooterSidebar url="tasks"/>
			
		</IconContext.Provider>
		</>
	);
};

export default SidebarTasks;
