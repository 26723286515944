import React, { Component } from "react";
import OlMap from "ol/Map.js";
import OlView from "ol/View.js";
import OlLayerTile from "ol/layer/Tile.js";
import OlLayerVector from "ol/layer/Vector.js";
import OlSourceVector from "ol/source/Vector";
import OlPoint from "ol/geom/Point";
import OlFeature from "ol/Feature";
import OlSourceOSM from "ol/source/OSM";
import XYZ from 'ol/source/XYZ'
import * as proj from "ol/proj";
import {
  Select as OlSelect,
  defaults as OlDefaultInteractions
} from "ol/interaction";
import { click as olClick } from "ol/events/condition";
import {
  Fill as OlFill,
  Stroke as OlStroke,
  Style as OlStyle,
  Icon as OlIcon
} from "ol/style";
import Overlay from "ol/Overlay";
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import LayerSwitcher from 'ol-layerswitcher';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import i18n from "i18n-react";
import HidePng from "../../img/hide.png";
import ShowPng from "../../img/show.png";
import lateStartImg from "../../img/taskStatus/lateStart.png";
import finalizedImg from "../../img/taskStatus/finalized.png";
import endingImg from "../../img/taskStatus/ending.png";
import inProcessImg from "../../img/taskStatus/inProcess.png";
import pendingImg from "../../img/taskStatus/pending.png";
import {FullScreen,defaults as defaultControls} from "ol/control";
import { getDateTimeForShow } from "./getTaskFunctions";
import {stopPropagation} from "../../components/SidebarFunctions";

const createPoint = (task) => {
    var imgSrc = "";
    if(task.itemProps.style.background == "#1B9CD9"){
        imgSrc = pendingImg;
    }else if(task.itemProps.style.background == "#93ccea"){
        imgSrc = inProcessImg;
    }else if(task.itemProps.style.background == "#ffe440"){
        imgSrc = endingImg;
    }else if(task.itemProps.style.background == "#F08080"){
        imgSrc = lateStartImg;
    }else if(task.itemProps.style.background == "#8DBE22"){
        imgSrc = finalizedImg;
    }

    const iconStyle = new OlStyle({
        image: new OlIcon({
            anchor: [0.5, 0.5],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            scale: 0.5,
            src: imgSrc
        })
    });

    const iconFeature = new OlFeature({
        type: "icon",
        geometry: new OlPoint(proj.fromLonLat([task.itemProps.officeLongitude,task.itemProps.officeLatitude])),
        id: task.id,
        taskToDo: task.itemProps.description,
        resonsibleUsername:task.itemProps.responsibleUsername,
        start_time:getDateTimeForShow(task.start_time._d),
        end_time:getDateTimeForShow(task.end_time._d),
        officeName:task.itemProps.officeName
    });
    iconFeature.setStyle(iconStyle);
    iconFeature.setId(task.id);
    return iconFeature;
};



class PublicMap extends Component {
  constructor(props) {
    super(props);
    let defaultCountryCenter;
    if(document.querySelector("#defaultCountryCenter").value === ""){
        defaultCountryCenter = [-64.278217, -29.219677, -53.335835, -17.759560];
    }else{
        defaultCountryCenter = JSON.parse(document.querySelector("#defaultCountryCenter").value)
    } 
    
    let statusSetting = [0,1,2,3,4,5];
    this.state = { 
        center: proj.fromLonLat([-57.363510,-24.322612]), 
        zoom: 6.5,
        defaultCountryCenter: defaultCountryCenter,
        tasks: props.items,
        heightTasks:"",
        statusSetting: statusSetting,
        popUpContent: [] 
     };
    this.showTask = props.showTask;
    let heightSplit = document.querySelector("#bodyContent").style.maxHeight;
    this.state.heightTasks = heightSplit;
    this.features = [];
    for(let i = 0; i < this.state.tasks.length; i++){
        if(this.state.tasks[i].itemProps.officeLatitude != 0 && this.state.tasks[i].itemProps.officeLongitude != 0){
            this.features.push(createPoint(this.state.tasks[i]));
        }
    }
    
    let layersToAdd = [];
    let layersMapList = 'ghyb,gphy,gsat,osmap,gmap';
    layersMapList = layersMapList.split(",");
    let visible = false;
    let defaultMap = "osmap";
    for (let i=0; i < layersMapList.length; i++){
        if (layersMapList[i] == "osmap"){
            if(defaultMap == "osmap"){
                visible = true;
            }
            let osm = new OlLayerTile({
                title: 'Open Street',
                type: 'base',
                visible: visible,
                source: new OlSourceOSM()
            });
            layersToAdd.push(osm);
            visible = false;
        }else if(layersMapList[i] == "gsat"){
            if(defaultMap == "gsat"){
                visible = true;
            }
            let satellite = new OlLayerTile({
                title: 'Google Satellite',
                type: 'base',
                visible: visible,
                source: new OlSourceOSM({
                    url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                })
            });
            layersToAdd.push(satellite);
            visible = false;
        }else if(layersMapList[i] == "ghyb"){
            if(defaultMap == "ghyb"){
                visible = true;
            }
            let hibryd = new OlLayerTile({
                title: 'Google Hybrid',
                type: 'base',
                visible: visible,
                source: new OlSourceOSM({
                    url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
                })
            });
            layersToAdd.push(hibryd);
            visible = false;
        }else if(layersMapList[i] == "gmap"){
            if(defaultMap == "gmap"){
                visible = true;
            }
            let street = new OlLayerTile({
                title: 'Google Street',
                type: 'base',
                visible: visible,
                source: new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
                })
            })
            layersToAdd.push(street);
            visible = false;
        }
    }
    
    this.taskLayer = new OlLayerVector({
        source: new OlSourceVector({
          features: this.features
        }),
        id: "task"
    })
    layersToAdd.push(this.taskLayer);

    this.olmap = new OlMap({
      target: null,
      layers: layersToAdd,
      controls: defaultControls().extend([new FullScreen()]),
      view: new OlView({
        center: this.state.center,
        zoom: this.state.zoom
      })
    });

    this.olmap.addControl(new LayerSwitcher({ reverse: false }));
    
    this.olmap.on('loadstart', ()=>{
        this.olmap.getTargetElement().classList.add('spinner');
    })

    this.olmap.on('loadend', ()=>{
        this.olmap.getTargetElement().classList.remove('spinner');
    })

  }


    componentDidMount() {
        this.olmap.updateSize(); 
        var lonlanExtent = proj.transformExtent(this.state.defaultCountryCenter, proj.get( "EPSG:4326" ), proj.get('EPSG:3857'))
        this.olmap.getView().fit(lonlanExtent);
        this.olmap.getView().setZoom(6.5);

        // const pointSelect = new OlSelect({
        // condition: (e) => {
        //     return olClick(e);
        // },
        // filter: (f) => {
        //     if (f.getGeometry().getType() === "Point") {
        //     return true;
        //     }
        // },
        // multi: true,
        // hitTolerance: 10,
        // style: new OlStyle({
        //     image: new OlIcon({
        //     anchor: [0.5, 48],
        //     anchorXUnits: "fraction",
        //     anchorYUnits: "pixels",
        //     src: "https://openlayers.org/en/latest/examples/data/icon.png"
        //     })
        // })
        // });
        // pointSelect.on("select", (e) => console.log(e.selected));
        // this.olmap.addInteraction(pointSelect);
    
        this.olmap.setTarget("map");

        if(this.taskLayer.getSource().getFeatures().length > 0){
            this.zoomToExtendsPoint();
        }

        this.initialicePopup();
        
    }

    zoomToExtendsPoint = () => {
        this.olmap.updateSize();
        this.olmap.getView().fit(this.taskLayer.getSource().getExtent(),{
            size: this.olmap.getSize(),padding: [50, 50, 50, 50],
        });
        if(this.taskLayer.getSource().getFeatures().length == 1){
            this.olmap.getView().setZoom(16);
        }
    } 

    initialicePopup = () => {
        const container = document.querySelector('#popupV2');

        this.overlay = new Overlay({
            element: container,
            autoPan: {
                animation: {
                duration: 150,
                },
            },
        });
        
        this.olmap.addOverlay(this.overlay);
        this.singleclick = this.olmap.on("click", (e) => {
            this.showPopUpSingleClick(e);
        })

    }

    closePopUp = () => {
        this.overlay.setPosition(undefined);
    } 

    showPopUpSingleClick = (e) => {
        this.olmap.forEachFeatureAtPixel(e.pixel,
        (feature, layer) => {
            this.setState({popUpContent:[feature.values_]})
        });
        const pixel = this.olmap.getEventPixel(e.originalEvent);
        const hit = this.olmap.hasFeatureAtPixel(pixel);
        if(hit){
            //How to get all features you click on.
            //const featureArray = map.getFeaturesAtPixel(pixel);
            this.overlay.element.hidden = false;
            this.overlay.setPosition(e.coordinate);
        }else{
            this.overlay.element.hidden = true;
            this.collapseDiv("","nozoom")
        }
    } 

    collapseDiv = (task,flag) => {
        var collapse = document.querySelector("#taskDetail"+task.id);
        if(!this.state.collapse2){
            if(collapse == null){
                return;
            }else if(collapse.style.display == "none"){
                collapse.style.display = "block";
                this.setState({collapse2:collapse});
                if(flag != "breakDivBlock"){
                    this.zoomOffice(task); 
                } 
            }
        }else{
            if(collapse != null){
                if(collapse.style.display == "block"){
                    collapse.style.display = "none";
                    this.setState({collapse2:""})
                    this.overlay.setPosition(undefined);
                    if(flag != "nozoom" && flag != "breakDivBlock"){
                        this.zoomToExtendsPoint();
                    } 
                    return;
                }else{ 
                    this.state.collapse2.style.display = "none";
                    collapse.style.display = "block";
                    this.setState({collapse2:collapse});
                    if(flag != "breakDivBlock"){
                        this.zoomOffice(task); 
                    }
                    return;
                }
            }else{
                this.state.collapse2.style.display = "none";
                this.setState({collapse2:""})
                if(flag != "nozoom"){
                    this.zoomToExtendsPoint();
                } 
                return;
            } 
        } 
    }

    zoomOffice = (task) => {
        let features = this.taskLayer.getSource().getFeatures();
        for(let i = 0; i < features.length; i++){
            if(task.id == features[i].values_.id){
                this.overlay.element.hidden = true;
                this.olmap.getView().fit(features[i].getGeometry());
                this.setState({popUpContent:[features[i].values_]})
                this.overlay.element.hidden = false;
                this.overlay.setPosition(features[i].getGeometry().flatCoordinates);
                this.olmap.getView().setZoom(15);
            } 
        } 
    } 

    showHideMarkersInfo = () => {
        if(document.querySelector("#showImg").style.display == "none"){
            document.querySelector("#showImg").style.display = "block";
            document.querySelector("#hideImg").style.display = "none";
            document.querySelector("#markersImgDiv").style.display = "none";
        }else{
            document.querySelector("#showImg").style.display = "none";
            document.querySelector("#hideImg").style.display = "block";
            document.querySelector("#markersImgDiv").style.display = "";
        }
    }

    showTaskFromMap = (itemId, e, time) => {
        stopPropagation(e);
        this.showTask(itemId, e, time)
    } 


  render() {
    return (
        <div className='row'>
            <input type="hidden" value="ghyb,gphy,gsat,osmap,gmap"/>
            <div className='divOrder_2 col-sm-12 col-md-5 col-lg-4 col-xl-3 pr-0' style={{overflow:"auto",maxHeight:this.state.heightTasks}}>
                {this.state.tasks.length == 0 && <>{i18n.translate("there_are_no_results_for_the_search")}</>}
                {this.state.tasks.map((t) =>{
                    return(
                        <>
                            <br/>
                            <div onClick={() => this.collapseDiv(t)} id={'task-'+t.id} className='taskBox' style={{borderLeft:"4px solid "+t.itemProps.style.background}}>
                                <div className='form-group'>
                                    <label>
                                    <span className='bolder'>{i18n.translate("responsible")}: </span>
                                    {t.itemProps.responsibleUsername}  
                                    </label>
                                </div>
                                <div className='form-group'>
                                    <label>
                                        <span className='bolder'>{i18n.translate("task_title")}: </span>
                                        {t.itemProps.description}  
                                    </label>
                                </div>
                                <div className='form-group'>
                                    <label>
                                    <span className='bolder'>{i18n.translate("state")}: </span>
                                        {t.itemProps.status}  
                                    </label>
                                </div>
                                <div className='form-group' id={'taskDetail'+t.id}  style={{display:"none"}}>
                                    <span className="bolder">{i18n.translate("start_date")}: </span>{t.startDateForShow} 
                                    <br/>
                                    <span className="bolder">{i18n.translate("end_date")}: </span>{t.endDateForShow}
                                    <br/>
                                    <button className="buttonMzatePrimary" onClick={(e) => this.showTaskFromMap(t.id,e)}>{i18n.translate("default_show")}</button>
                                </div>
                            </div>
                        </>
                    )
                } )} 
            </div>
            <div className='divOrder_1 col-sm-12 col-md-7 col-lg-8 col-xl-9 mh-100'>
                <div id="map" className="ol-map" style={{height:this.state.heightTasks}}></div>
                <div id="popupV2" className="ol-popup">
                    <a id="popup-closer" onClick={() => this.closePopUp()} className="ol-popup-closer"></a>
                    <div id="popup-content">
                        {this.state.popUpContent.map((task) =>{
                            return(
                                <>
                                    <span className="bolder">{i18n.translate("responsible")}: </span>
                                    {task.resonsibleUsername}
                                    <br/>
                                    <span className="bolder">{i18n.translate("stablishment")}: </span>
                                    {task.officeName} 
                                    <br/>
                                    <span className="bolder">{i18n.translate("start_date")}: </span>
                                    {task.start_time}
                                    <br/>
                                    <span className="bolder">{i18n.translate("end_date")}: </span>
                                    {task.end_time}
                                </>
                            )
                        })} 
                    </div>
                </div>
                <div class="markersInfo">
                    <div id="markersImgDiv" style={{paddingTop:"10px"}}>
                        {this.state.statusSetting.map((setting) => {
                            return(
                                <>
                                    {setting == 0 && 
                                        <div style={{borderBottom:"3px solid #1B9CD9",marginRight:"2px"}}><span style={{fontWeight: "bold"}}>{i18n.translate("pending")}</span></div>
                                    }
                                    {setting == 1 &&
                                        <div style={{borderBottom:"3px solid #93ccea",marginRight:"2px"}}><span style={{fontWeight: "bold",marginRight:"2px"}}>{i18n.translate("inProcess")}</span></div>
                                    }
                                    {setting == 2 &&
                                        <div style={{borderBottom:"3px solid #ffe440",marginRight:"2px"}}><span style={{fontWeight: "bold",marginRight:"2px"}}>{i18n.translate("ending")}</span></div>
                                    }
                                    {setting == 3 &&
                                        <div style={{borderBottom:"3px solid #F08080",marginRight:"2px"}}><span style={{fontWeight: "bold",marginRight:"2px"}}>{i18n.translate("lateStart")}</span></div>
                                    }
                                    {setting == 4 &&
                                        <div style={{borderBottom:"3px solid #8DBE22",marginRight:"2px"}}><span style={{fontWeight: "bold",marginRight:"2px"}}>{i18n.translate("finalized")}</span></div>
                                    }
                                </>
                            )
                        })}
                    </div>
                    <div tooltip="" onClick={() => this.showHideMarkersInfo()} style={{cursor:"pointer"}}>
                        <img id="hideImg" src={HidePng} />
                        <img style={{display:"none"}} id="showImg" src={ShowPng} />
                    </div>
                </div>
            </div>
            
        </div>
    )
  }
}

export default PublicMap;