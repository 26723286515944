import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarAdministration from "../components/SidebarAdministration";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions";
import NotificationEvents from "../pages/adminContent/NotificationEvents"; 

const Administration = ({module,token}) => {
    var content = <NotificationEvents token={token}/>;

    if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
        window.history.replaceState(null,document.title,window.location.origin + "/" + '?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
    }

    var flag = 0;
    const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }

    return (
        <div className="tab-pane fade" id="nav-controlAdministration" role="tabpanel" aria-labelledby="nav-controlAdministration-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarAdministration/>
            </div>
            
            <div className="content" id="contentAdministration">
                {content}
            </div>
        </div>
    );
} 

export default Administration;