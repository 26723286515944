import axios from 'axios';
import i18n from 'i18n-react';
import { toastFunction } from '../tasksContent/getTaskFunctions';
import { Col, Row, Table } from 'reactstrap';

export const url = process.env.REACT_APP_API_URL;

export const downloadInvoicePdf = (billing) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${url}fePyInvoice/download?feInvoiceReplicaId=${encodeURI(billing.replicaId)}`,
            headers: { 'Authorization': document.querySelector('#token').value },
            responseType: 'blob',
        })
        .then(response => {
            if (response.status === 200) {
                const FileDownload = require('js-file-download');
                toastFunction(i18n.translate('download_started'), 'success');
                FileDownload(response.data, `${billing.invoiceNumber}.pdf`);
                resolve(true); 
            } else {
                toastFunction(i18n.translate('an_error_has_ocurred'), 'error');
                resolve(false); 
            }
        })
        .catch(error => {
            reject(error); 
        });
    });
};

export const billingContentShow = (billing) => {
    return (
        <>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("date_issue")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.invoicingDate}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_number")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.invoiceNumber}  
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("document_number")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.customerFiscalNumber}  
                    </label>
                </Col>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_customer_name")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.customerName}  
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("default_address")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.customerAddress}  
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("invoice_sale_condition")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.type}  
                    </label>
                </Col>
                {billing.type === i18n.translate("default_credit") && 
                    <Col>
                        <label style={{fontWeight:'bolder'}}>
                            ({billing.term} {i18n.translate("default_days")}) 
                        </label>
                    </Col>
                } 
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("default_currency")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.currency}  
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label style={{fontWeight:'bolder'}}>
                        {i18n.translate("default_user")} 
                    </label>
                    <br></br>
                    <label>
                        {billing.responsible}  
                    </label>
                </Col>
            </Row>
            <Table className='table table-hover'>
                <tbody>
                    <tr>
                        <th className='firstcell'>{i18n.translate("default_code")}</th>
                        <th className='cellgroup'>{i18n.translate("description")}</th>
                        <th className='cellgroup'>{i18n.translate("storage_price")}</th>
                        <th className='cellgroup'>{i18n.translate("default_amount")}</th>
                        <th className='cellgroup'>{i18n.translate("invoice_exempt")}</th>
                        <th className='cellgroup'>{i18n.translate("invoice_vat_5")}</th>
                        <th className='cellgroup'>{i18n.translate("invoice_vat_10")}</th>
                        <th className='cellgroup'>{i18n.translate("default_subtotal")}</th>
                    </tr>
                    {billing.detail.map((det) =>{
                        return(
                            <tr key={det.vproductCode}>
                                <td>
                                    {det.vproductCode} 
                                </td>
                                <td>
                                    {det.vproductName} 
                                </td>
                                <td>
                                    {det.price} 
                                </td>
                                <td>
                                    {det.quantity} 
                                </td>
                                <td>
                                    {det.exemptAmount} 
                                </td>
                                <td>
                                    {det.productVatRate === 5 ? det.vatAmount : 0} 
                                </td>
                                <td>
                                    {det.productVatRate === 10 ? det.vatAmount : 0} 
                                </td>
                                <td>
                                    {det.subTotal} 
                                </td>
                            </tr>
                        )
                    })} 
                </tbody>
            </Table>
            <Row style={{marginBottom:'10px'}}>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("invoice_total_exempt")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.exemptTotal}  
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("total_5")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.vat05Total}  
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("total_10")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.vat10Total}  
                        </label>
                    </div>
                </Col>
                <Col>
                    <div className="detailTotalInvoice">
                        <label style={{fontWeight:'bolder'}}>
                            {i18n.translate("net_total")} 
                        </label>
                        <label style={{marginLeft: '5px'}}>
                            {billing.netTotal}  
                        </label>
                    </div>
                </Col>
            </Row>
        </>
    )
}
