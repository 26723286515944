import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Topbar from "./components/Topbar"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Tasks from './pages/Tasks'
import Videos from './pages/Videos';
import AdministrationMzate from './pages/AdministrationMzate';
import i18n from "i18n-react";
import MyAccount from './pages/MyAccount';
import ElectronicBilling from './pages/ElectronicBilling';
import InvoiceShow from './pages/electronicBillingContent/InvoiceShow';
import Administration from './pages/Administration';

let language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

if (language.length > 2) {
  language = language.split("-")[0];
  language = language.split("_")[0];
}

if(language !== "es" && language !== "en"){
  language = "en";
} 

i18n.setTexts(require(`./i18n/`+ language +`.json`))
let currentUrl = window.location.href;
let params = currentUrl.split("?token=");
let module = params[0].split("?module=");
let invoiceId = module[0].split("?invoiceId=");
let instanceType = invoiceId[0].split("?instanceType=");
let independent = instanceType[0].split("?independent="); 
let component = <Tasks language={language} independent={independent[1]} instanceType={instanceType[1]}/>;
if(module[1] === 'storage'){
  component = <MyAccount token={decodeURIComponent(params[1])} independent={independent[1]} instanceType={instanceType[1]}/>;
}else if(module[1] === 'administrationMzate'){
  component = <AdministrationMzate/>;
}else if(module[1] === 'tutorialVideos'){
  component = <Videos token={decodeURIComponent(params[1])} independent={independent[1]} instanceType={instanceType[1]}/>;
}else if(module[1] === 'electronicBilling'){
  component = <ElectronicBilling module={module[1]} token={decodeURIComponent(params[1])} independent={independent[1]} instanceType={instanceType[1]}/>;
}else if(module[1] === 'invoiceShow'){
  component = <InvoiceShow module={module[1]} token={decodeURIComponent(params[1])} invoiceId={invoiceId[1]} independent={independent[1]} instanceType={instanceType[1]}/>;
}else if(module[1] === 'notificationEvents'){
  component = <Administration module={module[1]} token={decodeURIComponent(params[1])} independent={independent[1]} instanceType={instanceType[1]}/>; 
}

function App() {
  return (
    <Router>
      <Topbar isInvoiceShow={module[1] == 'invoiceShow' ? true : false}/>
      <Routes>
        <Route path="/" exact element={component} />
        <Route path="/Tareas/*" exact element={<Tasks language={language}/>} />
        <Route path="/AdministrationMzate/*" exact element={<AdministrationMzate/>} /> 
        <Route path='/MyAccount/storage' exact element={<MyAccount/>} />
        <Route path="/Videos/*" exact element={<Videos/>} /> 
        <Route path="/ElectronicBilling/*" exact element={<ElectronicBilling/>} />      
        <Route path="/Videos/*" exact element={<Videos/>} />  
        <Route path='/Administration/events' exact element={<Administration/>} />    
      </Routes>
    </Router>
  );
}

export default App;
