import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import { DescriptionConfig, DescriptionGIE, NewGIE } from './DescriptionConfig';

const url = process.env.REACT_APP_API_URL;

class BooleanTemlate extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selected: "",
            form:props.array,
            detail: props.det
        } 
        let selected;
        if(this.state.detail){
            selected = this.state.detail.value;
        }else{
            selected = this.state.form.detail[0].value;
            this.state.detail = this.state.form.detail[0];
        }
        
    }

    handleChange=e=>{
    }

    render(){
        return(
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <DescriptionConfig keyValue={this.state.form.key}/>
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("current_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        {i18n.translate(this.state.detail.value)}
                    </div>
                </div>
                {this.state.form.update != false &&
                    <DescriptionGIE type={this.state.detail.type}/>
                }
                {this.state.form.update == false && 
                    <NewGIE/>
                }
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("new_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                    <select className="form-select selectStandard newValue" onChange={this.handleChange} name="tenantId">
                        {this.state.detail.value == "true" ?  <option value="true" selected>{i18n.translate("true")}</option> : <option value="true">{i18n.translate("true")}</option>} 
                        {this.state.detail.value == "false" ?  <option value="false" selected>{i18n.translate("false")}</option> : <option value="false">{i18n.translate("false")}</option>}
                    </select>
                    </div>
                </div>
            </div>
            
        )
    } 
    
} 

export default BooleanTemlate;