import React from "react";
import i18n from "i18n-react";

var html = "";
var values;

export const DynamicMapShow = (props) => {
    var html = "";
    values = JSON.parse(props.array.value);
    html += createDynamicMapHtml(values);
    
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: html}}>
        </div>
        
    )
}
export const DynamicMap = (props) => {
   
    var detail = props.array;
    html = "";
    {detail.detail.map((elem) =>{
        if(!detail.show){
            html += "<li>";
            if(elem.type == 0){
                html += "<b>"+i18n.translate("default_predeterminated")+": </b>";
                html += "<br>";
            }else{
                html += "<b>"+elem.description+"</b>";
                html += "<br>";
            }
        }
        values = JSON.parse(elem.value);
        html += createDynamicMapHtml(values);
        
        if(!detail.show){
            html += "</li>";
        }
    })} 
    if(!detail.show){
        return (
            <ul dangerouslySetInnerHTML={{__html: html}}></ul>
        );
    }else{
        return (
            <span dangerouslySetInnerHTML={{__html: html}}></span>
        )
    }
};

export const ArrayStringShow = (props) => {
    var count = 1;
    var html = "";
    values = JSON.parse(props.array.value);
    values.map((e) => { 
            html += count+ " - "+e;
            html += "<br>";
            count++;
    });
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: html}}>
        </div>
        
    )
}

export const ArrayString = (props) => {
    var detail = props.array;
    var count = 1;
    let img;
    html = "";
    {detail.detail.map((elem) =>{
        if(!detail.show){
            html += "<li>";
            if(elem.type == 0){
                html += "<b>"+i18n.translate("default_predeterminated")+": </b>";
                html += "<br>";
            }else{
                html += "<b>"+elem.description+"</b>";
                html += "<br>";
            }
        }
        values = JSON.parse(elem.value);
        values.map((e) => { 
                html += count+ " - "+e;
                html += "<br>";
                count++;
        });
        if(!detail.show){
            html += "</li>";
        }
    })} 
    if(!detail.show){
        return (
            <ul dangerouslySetInnerHTML={{__html: html}}></ul>
        );
    }else{
        return (
            <span dangerouslySetInnerHTML={{__html: html}}></span>
        )
    }
};

export const IntervalDayAndHourShow = (props) => {
    var intervals, daysAndHours, daysToSplit, hoursToSplit, days, hours;
    var daysToShow = [];  
    var html = "";
    intervals = props.array.value.split("|");
    for(var i = 0; i < intervals.length; i++){
        daysAndHours = intervals[i].split(";");
        daysToSplit = daysAndHours[0];
        hoursToSplit = daysAndHours[1];
        days = daysToSplit.split(",");
        hours = hoursToSplit.split("-");
        daysToShow = createIntervalDayAndHourHtml(days);
        
        html += daysToShow;
        html += hours;
        html += "<br>";
        daysToShow = [];
    }
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: html}}>
        </div>
        
    ) 
}  

export const IntervalDayAndHour = (props) => {
    let img;
    var rowCounts = 0;
    var detail = props.array;
    const optionsHour = [
        { value: '00'},{ value: '01'},{ value: '02'},{ value: '03'},{ value: '04'},{ value: '05'},{ value: '06'},
        { value: '07'},{ value: '08'},{ value: '09'},{ value: '10'},{ value: '11'},{ value: '12'},{ value: '13'},
        { value: '14'},{ value: '15'},{ value: '16'},{ value: '17'},{ value: '18'},{ value: '19'},{ value: '20'},
        { value: '21'},{ value: '22'},{ value: '23'}
    ]
    const optionsMinute = [
        { value: "00"},{ value: "05"},{ value: "10"},{ value: "15"},{ value: "20"},{ value: "25"},{ value: "30"},
        { value: "35"},{ value: "40"},{ value: "45"},{ value: "50"},{ value: "55"}, 
    ]  
    html = "";
    var intervals, interval1, interval2, days;
    var daysHour, daysForSplit, daysHour, hourForSplit, hours;
    var checkDays = {"monday":false,"tuesday":false,"wednesday":false,"thursday":false,"friday":false,"saturday":false,"sunday":false}; 
    var checkHours;
    var d = [];
    var h = []; 
    var checkTimeHour = [];
    var dayTime = [];
    var dayForShow = [];
    var timeForShow = []; 
    for(var i = 0; i < detail.detail.length; i++){
        intervals = detail.detail[i].value.split("|");
        for(var j = 0; j < intervals.length; j++){
            daysHour = intervals[j].split(";");
            daysForSplit = daysHour[0];
            days = daysForSplit.split(",");
            hourForSplit = daysHour[1];
            hours = hourForSplit.split("-");
            checkHours = hourForSplit.split("-");
            for(var k = 0; k < days.length; k++){
                if(days[k] == "1"){
                    checkDays.monday = true;
                }else if(days[k] == "2"){
                    checkDays.tuesday = true;
                }else if(days[k] == "3"){
                    checkDays.wednesday = true;
                }else if(days[k] == "4"){
                    checkDays.thursday = true;
                }else if(days[k] == "5"){
                    checkDays.friday = true;
                }else if(days[k] == "6"){
                    checkDays.saturday = true;
                }else if(days[k] == "7"){
                    checkDays.sunday = true;
                }
                
            } 
            timeForShow.push(checkHours);
            d.push(checkDays);
            h.push(hours);
            hours.push(checkDays);
            checkTimeHour.push(hours)
            checkDays = {"monday":false,"tuesday":false,"wednesday":false,"thursday":false,"friday":false,"saturday":false,"sunday":false};
        } 
    }
    for(var j = 0; j < detail.detail.length; j++){
        timeForShow =[]; 
        if(!detail.show ){
            html += "<li>";
            if(detail.detail[j].type == 0){
                html += "<b>"+i18n.translate("default_predeterminated")+": </b>";
                html += "<br>";
            }else{
                html += "<b>"+detail.detail[j].description+"</b>";
                html += "<br>";
            } 
            intervals = detail.detail[j].value.split("|");
            for(var i = 0; i < intervals.length; i++){
                daysHour = intervals[i].split(";");
                daysForSplit = daysHour[0];
                days = daysForSplit.split(",");
                hourForSplit = daysHour[1];
                hours = hourForSplit.split("-");
                checkHours = hourForSplit.split("-");
                dayTime = createIntervalDayAndHourHtml(days);
                 
                timeForShow.push(checkHours);
                d.push(checkDays);
                h.push(hours);
                hours.push(checkDays);
                checkTimeHour.push(hours)
                html += dayTime;
                html += " ";
                html += timeForShow[i];
                html += "<br>";
                dayTime = [];
                
            }
        }
        
        if(!detail.show){
            html += "</li>";
        }
    }
    if(!detail.show){
        return (
            <ul dangerouslySetInnerHTML={{__html: html}}></ul>
        );
    }else{
        return (
            <span dangerouslySetInnerHTML={{__html: html}}></span>
        )
    } 
} 

export const BooleanShow = (props) =>{
    var html = "";
    html += "<label>"; 
    if(props.array.value == "true"){
        html += i18n.translate("true");
    }else{
        html += i18n.translate("false");
    }
    html += "</label>";
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: html}}>
        </div>
        
    )
}

export const Boolean = (props) =>{
    var detail = props.array;
    html = "";
    {detail.detail.map((elem) =>{
        if(!detail.show){
            html += "<li>";
            if(elem.type == 0){
                html += "<b>"+i18n.translate("default_predeterminated")+": </b>";
            }else{
                html += "<b>"+elem.description+": </b>";
            } 
        } 
        html += "<label>"; 
        if(elem.value == "true"){
            html += i18n.translate("true");
        }else{
            html += i18n.translate("false");
        }
        html += "</label>";
        if(!detail.show){
            html += "</li>";
        }
    })} 
    if(!detail.show){
        return (
            <ul dangerouslySetInnerHTML={{__html: html}}></ul>
        );
    }else{
        return (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: html}}>
            </div>
            
        )
    } 
} 

export const ArrayOfMap = (props) => {
   
    var detail = props.array;
    html = "";
    {detail.detail.map((elem) =>{
        if(!detail.show){
            html += "<li>";
            if(elem.type == 0){
                html += "<b>"+i18n.translate("default_predeterminated")+": </b>";
                html += "<br>";
            }else{
                html += "<b>"+elem.description+"</b>";
                html += "<br>";
            }
        }
        values = JSON.parse(elem.value);
        html += createArrayOfMapHtml(values);  
        if(!detail.show){
            html += "</li>";
        }
    })} 
    if(!detail.show){
        return (
            <ul dangerouslySetInnerHTML={{__html: html}}></ul>
        );
    }else{
        return (
            <span dangerouslySetInnerHTML={{__html: html}}></span>
        )
    }
};

export const ArrayOfMapShow = (props) =>{
    var html = "";
    values = JSON.parse(props.array.value);
    html += createArrayOfMapHtml(values);
    
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12" dangerouslySetInnerHTML={{__html: html}}>
        </div>
        
    )
}

const createBooleanHtml = (value) => {
    var html = "";
    if(value == "true"){
        html += i18n.translate("true");
    }else{
        html += i18n.translate("false");
    }
    html += "<br>";
    return html;
}  

const createIntervalDayAndHourHtml = (days) => {
    var dayTime = []; 
    for(var k = 0; k < days.length; k++){
        if(days[k] == "1"){
            dayTime.push(i18n.translate("monday"));
        }else if(days[k] == "2"){
            dayTime.push(i18n.translate("tuesday"));
        }else if(days[k] == "3"){
            dayTime.push(i18n.translate("wednesday"));
        }else if(days[k] == "4"){
            dayTime.push(i18n.translate("thursday"));
        }else if(days[k] == "5"){
            dayTime.push(i18n.translate("friday"));
        }else if(days[k] == "6"){
            dayTime.push(i18n.translate("saturday"));
        }else if(days[k] == "7"){
            dayTime.push(i18n.translate("sunday"));
        }
    }
    return dayTime;
}  

const createArrayOfMapHtml = (values) =>  {
    var html = "";
    {values.map((val,i) => {
        html += "<b>"+(i+1)+" - "+val.type+"</b>";
        html += "<br>";
        if(val.settings.required_start_photo){
            html += "<span style='padding-left: 16px;'>";
            html += "<b>"+i18n.translate("required_start_photo")+": </b>";
            if(val.settings.required_start_photo.value == "true"){
                html += i18n.translate("true");
            }else{
                html += i18n.translate("false");
            } 
            html += "</span>";
            html += "<br>";
        }
        if(val.settings.required_end_photo){
            html += "<span style='padding-left: 16px;'>";
            html += "<b>"+i18n.translate("required_end_photo")+": </b>";
            if(val.settings.required_end_photo.value == "true"){
                html += i18n.translate("true");
            }else{
                html += i18n.translate("false");
            } 
            html += "</span>";
            html += "<br>";
        }
        if(val.settings.required_signature){
            html += "<span style='padding-left: 16px;'>";
            html += "<b>"+i18n.translate("required_signature")+": </b>";
            if(val.settings.required_signature.value == "true"){
                html += i18n.translate("true");
            }else{
                html += i18n.translate("false");
            } 
            html += "</span>";
            html += "<br>";
        }
        if(val.settings.standard_duration){
            html += "<span style='padding-left: 16px;'>";
            html += "<b>"+i18n.translate("standard_duration")+": </b>";
            html += val.settings.standard_duration.value+" "+i18n.translate("default_minutes");
            html += "</span>";
            html += "<br>";
        }
        if(val.settings.tasks_to_do){
            html += "<span style='padding-left: 16px;'>";
            html += "<b>"+i18n.translate("tasks_to_do")+": </b>";
            {val.settings.tasks_to_do.value.map((tasks, j) =>{
                if(j != 0){
                    html += ", ";
                } 
                html += tasks;
            })} 
            html += "</span>";
            html += "<br>"
        } 
    })}
    return html;
}

const createDynamicMapHtml = (values) =>  {
    var html = "";
    Object.keys(values).forEach((e) => { 
        html += "<b>"+i18n.translate(e)+ " </b>:";
        if(values[e].type == 'boolean' || values[e].type == 'BOOLEAN'){
            html += createBooleanHtml(values[e].value);
        }else if(values[e].type == 'numeric' || values[e].type == 'int'){
            html += values[e].value;
            html += "<br>";
        }else if(values[e].type == 'ARRAY_STRING'){
            values[e].value.map((e) =>{
                html += "<br>";
                html += e;
            })
        } 
    });
    return html;
}