export const getIntervalDayHourValues = () => {
    var val = "";
    var trLength = document.querySelectorAll(".modalBody tr.trWeek").length;
    var td;
    var tdIntervalTime;
    for(var i = 0; i < trLength; i++){
        td = document.querySelectorAll(".weekDay-"+i);
        for(var k = 0; k < td.length; k++){
            if(td[k].children[0].getAttribute("name") == "monday" && td[k].children[0].value == "true"){
                val += 1;
                val += ",";
            }else if(td[k].children[0].getAttribute("name") == "tuesday" && td[k].children[0].value == "true"){
                val += 2;
                val += ",";
            }else if(td[k].children[0].getAttribute("name") == "wednesday" && td[k].children[0].value == "true"){
                val += 3;
                val += ",";
            }else if(td[k].children[0].getAttribute("name") == "thursday" && td[k].children[0].value == "true"){
                val += 4;
                val += ",";
            }else if(td[k].children[0].getAttribute("name") == "friday" && td[k].children[0].value == "true"){
                val += 5;
                val += ",";
            }else if(td[k].children[0].getAttribute("name") == "saturday" && td[k].children[0].value == "true"){
                val += 6;
                val += ",";
            }else if(td[k].children[0].getAttribute("name") == "sunday" && td[k].children[0].value == "true"){
                val += 7;
                val += ",";
            }
        } 
        val = val.substring(0, val.length - 1);
        val += ";";
        tdIntervalTime = document.querySelectorAll(".timeInterval-"+i);
        for(var k = 0; k < tdIntervalTime.length; k++){
            val += tdIntervalTime[k].children[0].value;
            val += ":";
            val += tdIntervalTime[k].children[1].value;
            val += "-";
        }
        val = val.substring(0, val.length - 1);
        val += "|";
    } 
    val = val.substring(0, val.length - 1);
    return val;
}