import '../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useEffect, useState } from "react";

const DefaultModal = ({ show, title, content, buttons, modalClass, closeFunction, size }) => {
    const [isOpenModal, setIsOpenModal] = useState(show);
    useEffect(()=>{
		setIsOpenModal(true);
	}, [])
   
    return (
        <Modal isOpen={isOpenModal} 
        modalTransition={{ timeout: 0 }} backdropTransition={{ timeout: 0 }}
        size={size} >
            <ModalHeader className='modalHeader'>
                {title} 
                <button className='close' data-dismiss="close" onClick={closeFunction != undefined && closeFunction != '' ? closeFunction : ()=>setIsOpenModal(false)}>x</button>
            </ModalHeader>
            <ModalBody className={'modalBody '+modalClass}>
                {content} 
            </ModalBody>
            <ModalFooter>
                {buttons.map((b,i) =>{
                    return (
                        <button key={i} className={i != 0 ? 'buttonMzateSecondary' : 'buttonMzatePrimary'}
                        onClick={b.function != '' ? b.function : ()=>setIsOpenModal(false)}>{b.text}</button> 
                    )
                })} 
            </ModalFooter>
        </Modal>
    ) 
};
    
export default DefaultModal;

