import React from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";

export const SidebarDataElectronicBilling = [
    {
        title: "default_bills",
        path: "/ElectronicBilling/ElectronicBillingIndex",
        icon: <FaIcons.FaFileInvoice />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />
    },
    // {
    //     title: "credit_notes",
    //     path: "/ElectronicBilling/CreditNote",
    //     icon: <FaIcons.FaReceipt />,
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />
    // },
    {
        title: "invoice_data",
        path: "/ElectronicBilling/Faq",
        icon: <FaIcons.FaIdCard />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
        title: "default_products",
        path: "/ElectronicBilling/Products",
        icon: <FaIcons.FaCartPlus />,
        iconClosed: <FaIcons.FaChevronDown className="arrowIcon"/>,
        iconOpened: <FaIcons.FaChevronDown className="arrowIcon"/>,
        id: 'electronicBillingProducts',
        subNav: [
            {
                title: "default_search",
                path: "/ElectronicBilling/Products",
                icon: <FaIcons.FaCircle className="iconSubmenu"/>,
                id: "productsElectronicBilling"
            },
            // {
            //     title: "default_import",
            //     path: "/ElectronicBilling/ImportProducts",
            //     icon: <FaIcons.FaCircle className="iconSubmenu"/>,
            //     id: "importElectronicBilling"
            // }
        ]
    },
    // {
    //     title: "price_list",
    //     path: "/ElectronicBilling/PriceList",
    //     icon: <FaIcons.FaTags />,
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />,
    
    // }
]