import React from "react";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";

export const SidebarDataVideos = [
    {
        title: "videos_title",
        path: "/Videos/videos",
        icon: <FaIcons.FaVideo />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
    }
]