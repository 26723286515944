import '../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import ReactPagination from "react-paginate";

const DefaultPagination = ({ data, handlePageClick, perPage, className, totalCount }) => {
    const [pageCount, setPageCount] = useState(Math.ceil(data.length / perPage));
     useEffect(()=>{
        let totalCountToUse = data.length;
        if(totalCount){
            totalCountToUse = totalCount;
        } 
	 	setPageCount(Math.ceil(totalCountToUse / perPage));
	 })
   
    return (
        <div id='react-paginate' className={className} >
            <ReactPagination
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}/>
        </div>
    ) 
};
    
export default DefaultPagination;