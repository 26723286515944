import React from "react";
import LogoContainer from "../components/logoContainer";
import SidebarMyAccount from "../components/SidebarMyAccount";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions"; 
import IndexStorage from "./adminContent/IndexStorage";

const MyAccount = ({module,token}) =>{
    var content = <IndexStorage token={token} />;

    if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
        window.history.replaceState(null,document.title,window.location.origin + "/" + '?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
    }

    var flag = 0;
	const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }
    

    return (
        <div className="tab-pane fade" id="nav-controlMyAccount" role="tabpanel" aria-labelledby="nav-controlMyAccount-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarMyAccount/>
            </div>
            
            <div className="content" id="contentMyAccount">
                {content}
            </div>
        </div>
    );
} 

export default MyAccount;