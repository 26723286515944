import React from "react";
import LogoContainer from "../components/logoContainer";
import { showSidebarCollapseFunction, hideSidebarCollapseFunction } from "../components/SidebarFunctions";
import ElectronicBillingIndex from "./electronicBillingContent/ElectronicBillingIndex"; 
import CreditNoteIndex from "./electronicBillingContent/CreditNoteIndex";
import InvoiceData from "./electronicBillingContent/InvoiceData";
import { useLocation } from 'react-router-dom';
import SidebarElectronicBilling from "../components/SidebarElectronicBilling";
import Products from "./electronicBillingContent/Products";
import PriceList from "./electronicBillingContent/PriceList";
import ImportProducts from "./electronicBillingContent/ImportProducts";

const ElectronicBilling = ({module,token, instanceType, independent}) => {
    var content = "";
    
    var url = useLocation().pathname;
    if(url === "/" || url === "/ElectronicBilling/ElectronicBillingIndex" || url === "/ElectronicBilling"){
        content = <ElectronicBillingIndex token={token} instanceType={instanceType} independent={independent} />;
    }else if(url === "/ElectronicBilling/CreditNote"){
        content = <CreditNoteIndex token={token} />;
    }else if(url === "/ElectronicBilling/Faq"){
        content = <InvoiceData token={token} />;
    }else if(url === "/ElectronicBilling/Products"){
        content = <Products token={token} />;
    }else if(url === "/ElectronicBilling/PriceList"){
        content = <PriceList token={token} />;
    }else if(url === "/ElectronicBilling/ImportProducts"){
        content = <ImportProducts token={token} />;
    } 


    if(document.querySelector("#token") != null && document.querySelector("#token").value != ''){
        window.history.replaceState(null,document.title,window.location.origin + "/" + '?module='+document.querySelector("#currentModule").value+"?token=" + document.querySelector("#token").value);
    }

    var flag = 0;
	const showSidebarCollapse = () => {
        
        if(document.querySelector(".sidebar2Collapse")){
            showSidebarCollapseFunction();
            flag = 1;
        }
	} 
    const hideSidebarCollapse = () => {
        if(flag == 1){
            hideSidebarCollapseFunction();
            flag = 0;
        } 
    }
    

    return (
        <div className="tab-pane fade" id="nav-controlElectronicBilling" role="tabpanel" aria-labelledby="nav-controlElectronicBilling-tab" style={{opacity: 1}}>
            <div className="sidebar2"  onMouseOver={showSidebarCollapse} onMouseOut={hideSidebarCollapse}>
                <LogoContainer/>
                <SidebarElectronicBilling/>
            </div>
            
            <div className="content" id="contentElectronicBilling">
                {content}
            </div>
        </div>
    );

} 

export default ElectronicBilling;