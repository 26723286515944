import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import RemoveContent from "../../img/remove_content.png";
import AddContent from "../../img/add_content.png";
import i18n from "i18n-react";
import { getArrayValues, setArrayValues } from './getArrayValues';
import { DescriptionConfig, DescriptionGIE, NewGIE } from './DescriptionConfig';

const url = process.env.REACT_APP_API_URL;

class ArrayTemplate extends React.Component {
    constructor(props){
        var count = 1;
        var values = [];
        var valuesToUpdate = [];
        var valuesToShow = [];
        super(props);
        this.state = {
            values: [],
            valuesToUpdate: [],
            valuesToShow: [], 
            form:props.array,
            detail: props.det
        } 
        if(this.state.detail){
            values = this.state.detail.value;
            values = JSON.parse(values);
            values.map((e) => {
                valuesToShow.push(count+ " - "+e)
                valuesToUpdate.push(e);
                count++; 
            });
        }else{
            {this.state.form.detail.map((elem) =>{
                if(elem.type == 0){
                    values = elem.value;
                    values = JSON.parse(values);
                    values.map((e) => {
                        valuesToShow.push(count+ " - "+e)
                        valuesToUpdate.push(e);
                        count++; 
                    });
                }
             })}
        }
          
         this.state.valuesToShow =  valuesToShow;
         this.state.valuesToUpdate =  valuesToUpdate;
    }

    handleChange=e=>{
        var newValues = []; 
        let inputs = document.querySelectorAll(".arrayStringValue input");
        let arrayInput = Array.from(inputs);
        arrayInput.map((s) => newValues.push(s.value));
        this.setState({valuesToUpdate:newValues});
        return newValues;
    }

    removeOption=e=>{
        this.setState({valuesToUpdate:getArrayValues(e)});
    }
    
    addOption = (e,key) =>{
        this.setState({valuesToUpdate:setArrayValues(e,key)});
    }

    render(){
        return(
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <DescriptionConfig keyValue={this.state.form.key}/>
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("current_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        {this.state.valuesToShow.map((det) =>{
                           return(
                            <label style={{display:'block'}}>{det}</label>
                            )
                        })} 
                    </div>
                </div>
                {this.state.form.update != false &&
                    <DescriptionGIE type={this.state.detail.type}/>
                }
                {this.state.form.update == false && 
                    <NewGIE/>
                }
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("new_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9 arrayStringNewValues'>
                        {this.state.valuesToUpdate.map((det,index) =>{
                           return(
                            <div class='arrayStringValue'>
                                {(this.state.form.key == 'TASK_STATUS' && (index != 0 && (index+1) != this.state.valuesToUpdate.length)) && <>
                                    <img onClick={this.removeOption} className='removeContent' src={RemoveContent} width="20px" height="20px"/>
                                </>}
                                {this.state.form.key != 'TASK_STATUS' && <>
                                    <img onClick={this.removeOption} className='removeContent' src={RemoveContent} width="20px" height="20px"/>
                                </>} 
                                <input onChange={this.handleChange} name={det} class='form-control inputStandard' type='text' value={det}/>
                            </div>
                            )
                        })}
                        <div>
                            <img onClick={(e) => this.addOption(e,this.state.form.key)} className='addContent' src={AddContent} width="20px" height="20px"/>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    } 
    
} 

export default ArrayTemplate;